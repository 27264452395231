import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AppPermission } from '../../_shared/models/user';
import { ApiService } from '../../_shared/services/api.service';
import { AuthService } from '../../_shared/services/auth.service';
import { UiService } from '../../_shared/services/ui.service';

@Component({
  selector: 'app-cache-manager',
  templateUrl: './cache-manager.component.html',
  styleUrls: ['./cache-manager.component.scss']
})
export class CacheManagerComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authService: AuthService) { }

  allSections: CacheSection[];
  openedSection: CacheSection;
  canEdit = true;
  @ViewChild('table') table: Table;

  ngOnInit() {
    this.canEdit = this.authService.isPermitted(AppPermission.mngSysSettings);

    this.allSections = [
      this.getZixiConfig()
    ];

    this.openSection(this.allSections[0]);
  }

  openSection(section) {
    this.openedSection = section;
    section.onLoad();
  }

  applyFilterGlobal($event, stringVal) {
    this.table.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  getZixiConfig(): CacheSection {
    var section = <CacheSection>{
      header: "Zixi Objects",
      entries: [],
      loading: false,
      columns: [
        { header: "Object Id", field: "zixiObjectId" },
        { header: "Object Type", field: "objectType" },
        { header: "Name", field: "name" }
      ]
    }

    section.onLoad = () => {
      section.loading = true;

      this.apiService.get({
        method: "zixi/cache",
        onSuccess: (r) => {
          section.entries = r.result;
          section.loading = false;
        }
      });
    }

    section.onTriggerRefresh = () => {
      section.entries = [];
      section.loading = true;      

      this.apiService.post({
        method: "zixi/cache/refresh",
        onSuccess: (r) => {
          this.uiService.toast.success("Zixi Object Cache Refreshed");
          section.onLoad();
        }
      });
    }

    return section;
  }
}

interface CacheSection {
  header: string;
  entries: any[];
  loading: boolean;
  columns: any[];
  onLoad: () => void;
  onTriggerRefresh: () => void;
}
