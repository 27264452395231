<div class="page-header">
  <h3 class="title">Users</h3>
  <div class="button-group" *ngIf="canEdit">
    <button pButton label="Add User" (click)="addNew()" class="p-button-sm p-button-outlined"></button>
  </div>
</div>
<div class="page-body">
  <p-table [value]="users"
           [scrollable]="true"
           scrollHeight="flex"
           sortField="firstName"
           [sortOrder]="1"
           selectionMode="single"
           [(selection)]="selectedUser"
           (onRowSelect)="selectUser($event)">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="firstName">First Name<p-sortIcon field="firstName"></p-sortIcon></th>
        <th pSortableColumn="lastName">Last Name<p-sortIcon field="lastName"></p-sortIcon></th>
        <th pSortableColumn="oktaLogin">Login<p-sortIcon field="oktaLogin"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr [pSelectableRow]="user">
        <td class="f-250">{{user.firstName}}</td>
        <td class="f-250">{{user.lastName}}</td>
        <td>{{user.oktaLogin}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
