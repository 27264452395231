import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppPermission, AppUser } from '../models/user';
import { ApiResponse } from '../models/models';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService) { }

  private isAuthorizedSubject = new BehaviorSubject<boolean>(null);
  isAuthorized$ = this.isAuthorizedSubject.asObservable();
  user: AppUser;

  init() {
    var authUrl = this.apiService.getUrl({ method: "user/authorize" });
    this.http.get<ApiResponse>(authUrl).subscribe((r) => {
      this.user = r.result;
      this.isAuthorizedSubject.next(true);
    }, (r: HttpErrorResponse) => {
      this.user = null;
      this.isAuthorizedSubject.next(false);
    });
  }

  isPermitted(permission: AppPermission) {
    return this.user?.permissions.includes(permission) === true;
  }

  isPermittedForStation(permission: AppPermission, stationId: number) {
    return this.user?.stationPermissions[permission]?.includes(stationId) === true;
  }
}
