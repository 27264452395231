import { Pipe, PipeTransform } from '@angular/core';
import { Station } from '../../_shared/models/models';

@Pipe({
  name: 'airingScheduleFilter',
  pure: false
})

export class AiringScheduleFilterPipe implements PipeTransform {
  transform(stations: Station[], filter: any): any {
    if (!stations || !filter) {
      return stations;
    }
    var items = stations;

    if (filter.stations?.length > 0) {
      var stationIds = filter.stations.map((s:Station) => s.id);

      items = items.filter(s => stationIds.includes(s.id));
    }

    if (filter.airings?.length > 0) {
      items = items.filter(s => s.airings.some(a => filter.airings.includes(a.title)));
    }

    if (filter.search) {
      var search = filter.search.toLowerCase();

      items = items.filter(s => {
        if (s.airings.some(a => a.title?.toLowerCase().includes(search)))
          return true;

        return false;
      });
    }

    return items;
  }
}
