import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_shared/services/api.service';
import { ConfirmationService } from 'primeng/api';
import { OverrideType } from 'src/app/_shared/models/enums';
import { AppPermission } from 'src/app/_shared/models/user';
import { QueueJobService, QueueJobActionData } from '../../_shared/components/queue-job/queue-job.service';
import { AuthService } from '../../_shared/services/auth.service';

@Component({
  selector: 'app-override-groups',
  templateUrl: './override-groups.component.html',
  styleUrls: ['./override-groups.component.scss']
})
export class OverrideGroupsComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private queueJobService: QueueJobService,
    private confirmationService: ConfirmationService) { }

  groups: any[];
  group: any;
  stations: any;
  partners: any;
  ui = { setOverrideMode: false, canEditGroups: false, canOverride: false };
  overrideModes: OverrideMode[] = [
    { type: null, label: "Run Schedule", styleClass: "p-button-primary" },
    { type: OverrideType.ForceBlackout, label: "Force Blackout", styleClass: "p-button-danger" },
    { type: OverrideType.ForceStream, label: "Force Streaming", styleClass: "p-button-success" },
  ];
  canEdit: boolean;

  ngOnInit() {
    this.ui.canOverride = this.authService.isPermitted(AppPermission.mngOverride);
    this.ui.canEditGroups = this.authService.isPermitted(AppPermission.mngOverrideGrp);
    this.authService.user

    this.refresh();
  }

  refresh() {
    this.apiService.get({
      method: "override/groups",
      blockUi: true,
      onSuccess: (r) => {
        this.groups = r.result.groups;
        this.stations = r.result.stations;
        this.partners = r.result.partners;

        this.groups.forEach(g => {
          g.stations.forEach(s => {
            var match = this.stations.find(x => x.id === s.id);
            if (match) {
              s.name = match.name;
              s.partnerOptions = match.partners;
            }
          });
        });
      }
    });
  }

  expand(group) {
    group.expand = !group.expand;
  }

  selectGroup(group) {
    this.group = {
      id: group.id,
      name: group.name,
      stations: {
        all: [],
        selected: []
      }
    };

    this.stations.forEach(station => {
      var groupStation = group.stations.find(s => s.id === station.id);

      var result = {
        ...station,
        selected: groupStation !== undefined,
        partners: this.partners.map(p => {
          return {
            ...p,
            selected: groupStation?.partnerIds.includes(p.id) === true,
            inactive: station.partners.includes(p.id) === false
          }
        })
      };

      this.group.stations.all.push(result);

      if (result.selected)
        this.group.stations.selected.push(result);
    });
  }

  selectStation($event) {
    this.group.stations.selected.push($event.station);
  }

  removeStation(station) {
    station.selected = false;
    this.group.stations.selected = this.group.stations.all.filter(s => s.selected);
  }

  addGroup() {
    this.selectGroup({ stations: [] });
    this.ui.setOverrideMode = false;
  }

  modify(group) {
    this.selectGroup(group);
    this.ui.setOverrideMode = false;
  }

  setOverride(group) {
    this.selectGroup(group);
    this.ui.setOverrideMode = true;
  }

  closeDialog() {
    this.group = undefined;
  }

  saveGroup() {
    var data = {
      id: this.group.id,
      name: this.group.name,
      items: []
    };

    this.group.stations.selected.forEach(s => {
      s.partners.forEach(p => {
        if (p.selected)
          data.items.push({ stationId: s.id, partnerId: p.id });
      });
    });

    this.apiService.post({
      method: "override/group/save",
      data,
      blockUi: true,
      onSuccess: (r) => {
        this.closeDialog();
        this.refresh();
      }
    });
  }

  deleteGroup() {
    this.confirmationService.confirm({
      header: `Delete ${this.group.name}`,
      message: `Are you sure that you want to permanently delete this group?`,
      acceptLabel: `Yes, Delete Group`,
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "p-button-danger",
      accept: () => {
        this.apiService.delete({
          method: "override/group",
          params: { groupId: this.group.id },
          blockUi: true,
          onSuccess: (r) => {
            this.closeDialog();
            this.refresh();
          }
        });
      }
    });
  }

  togglePartner(partner) {
    partner.selected = !partner.selected;
  }

  toggleAll(partnerId?) {
    var partners = this.group.stations.selected.map(s => s.partners.filter(p => !partnerId || (p.id === partnerId))).flat();
    var selectAll = partners.some(p => !p.selected);

    partners.forEach(p => { p.selected = selectAll; });
  }

  force(mode: OverrideMode) {
    var actions: QueueJobActionData[] = [];

    this.group.stations.selected.forEach(s => {
      s.partners.forEach(p => {
        if (p.selected)
          actions.push({
            stationId: s.id,
            partnerId: p.id,
            description: `${s.name} - ${p.name}`
          });
      });
    });
    var stationCount = new Set(actions.map(t => t.stationId)).size;
    var countMessage = `${stationCount} stations (${actions.length} total station partners)`;

    this.confirmationService.confirm({
      header: `Set Override on ${this.group.name}`,
      message: `Are you sure that you want to ${mode.label} on ${countMessage}?`,
      acceptLabel: `Yes, ${mode.label}`,
      rejectLabel: "Cancel",
      acceptButtonStyleClass: mode.styleClass,
      accept: () => {
        this.queueJobService.launchForOverride(actions, mode.type);
      }
    });
  }
}

interface OverrideMode {
  type: OverrideType,
  label: string,
  styleClass: string
}
