import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../_shared/services/api.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UiService } from '../../_shared/services/ui.service';
import { DateTime } from 'luxon';
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../../_shared/services/auth.service';
import { AppPermission } from '../../_shared/models/user';

@Component({
  selector: 'app-airing-summary',
  templateUrl: './airing-summary.component.html',
  styleUrls: ['./airing-summary.component.scss']
})
export class AiringSummaryComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authService: AuthService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  model: any;
  ui = {
    buttons: { blackoutAll: false, deleteAll: false },
    hasChanges: false,
    canEdit: false
  };
  timer: Subscription;

  ngOnInit(): void {
    if (this.config.data.airingId) {
      this.openAiring();
    } else {
      this.uiService.toast.error("Airing not found");
      this.close();
    }
  }

  ngOnDestroy() {
    this.close();

    if (this.timer)
      this.timer.unsubscribe();
  }

  openAiring() {
    this.apiService.get({
      method: `schedule/airing`,
      params: { airingId: this.config.data.airingId },
      onSuccess: (r) => {
        this.model = r.result;

        this.ui.canEdit = this.authService.isPermittedForStation(AppPermission.mngStnSub, this.model.airing.stationId);

        // Get formatted dates
        var airingStart = DateTime.fromISO(this.model.airing.startTimeLocal);
        var airingEnd = DateTime.fromISO(this.model.airing.endTimeLocal);
        this.model.startDate = airingStart.toLocaleString(DateTime.DATE_SHORT);
        this.model.startTime = airingStart.toLocaleString(DateTime.TIME_SIMPLE);
        this.model.endTime = airingEnd.toLocaleString(DateTime.TIME_SIMPLE);

        this.model.title = [this.model.airing.seriesTitle, this.model.airing.title].filter(x => x).join(" - ");
        this.model.duration = `${this.model.airing.duration} mins`;

        this.config.header = `${this.model.airing.callSign} - ${this.model.airing.seriesTitle || this.model.airing.title} - ${airingStart.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} ${this.model.startTime}`;

        this.model.events.forEach(ev => {
          ev.updatedBy = ev.createdBySignalEventRuleId ? `Rule #${ev.createdBySignalEventRuleId}` : ev.updatedBy;
        });

        this.configureBlackouts();
        var delay = (60 - DateTime.now().second) * 1000; // initial delay so refresh occurs at 1 second of each minute
        this.timer = timer(delay, 60000).subscribe(() => { this.configureBlackouts(); });
      }
    });
  }

  configureBlackouts() {
    var now = DateTime.now();
    var airingEnded = DateTime.fromISO(this.model.airing.endTimeLocal) <= now;

    this.model.partners = this.model.airing.partners.map(p => {
      var blackouts = this.model.events.filter(ev => ev.partnerId === p.partnerId);

      blackouts.forEach(b => {
        var blackoutEnded = DateTime.fromISO(b.eventEnd) <= now;

        b.allowDelete = this.ui.canEdit && !blackoutEnded;
      });

      return {
        ...p,
        blackouts,
        allowCreate: this.ui.canEdit && !airingEnded && !blackouts.some(b => b.allowDelete),
        allowDelete: this.ui.canEdit && blackouts.some(b => b.allowDelete)
      };
    });

    this.ui.buttons.blackoutAll = this.model.partners.every(p => p.allowCreate);
    this.ui.buttons.deleteAll = this.model.partners.some(p => p.allowDelete);
  }

  createBlackout(row) {
    this.executeCreate([row.partnerId]);
  }

  deleteBlackout(blackout) {
    this.executeDelete([blackout.signalEventGroupId]);
  }

  blackoutAll() {
    var partnerIds = this.model.partners
      .filter(p => p.allowCreate)
      .map(p => p.partnerId);

    this.executeCreate(partnerIds);
  }

  deleteAll() {
    var groupIds = this.model.partners
      .map(p => p.blackouts)
      .flat()
      .filter(b => b.allowDelete)
      .map(b => b.signalEventGroupId);

    this.executeDelete(groupIds);
  }

  close() {
    this.ref.close({ refresh: this.ui.hasChanges });
  }

  private executeCreate(partnerIds) {
    this.apiService.post({
      method: "signalEvent/create",
      data: {
        stationId: this.model.airing.stationId,
        startDate: this.model.airing.startTimeLocal,
        endDate: this.model.airing.endTimeLocal,
        startEventDescription: `Start Blackout [${this.model.title}]`,
        endEventDescription: `End Blackout [${this.model.title}]`,
        partnerIds
      },
      blockUi: true,
      onSuccess: (r) => {
        this.ui.hasChanges = true;
        this.openAiring();
      }
    });
  }

  private executeDelete(groupIds) {
    this.apiService.delete({
      method: `signalEvent`,
      params: { groupIds },
      blockUi: true,
      onSuccess: (r) => {
        this.ui.hasChanges = true;
        this.openAiring();
      }
    });
  }
}
