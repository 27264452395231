import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ApiService } from '../../_shared/services/api.service';
import { UiService } from '../../_shared/services/ui.service';

@Component({
  selector: 'app-adn-import',
  templateUrl: './adn-import.component.html',
  styleUrls: ['./adn-import.component.scss']
})

export class AdnImportComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public uiService: UiService) { }

  job: ImportJob;
  stations: Lookup[];
  adns: Lookup[];
  @ViewChild('textarea') textarea: ElementRef;

  ngOnInit() {
    this.adns = this.config.data.adns;
    this.getStations();

    this.job = <ImportJob>{ inputText: "", step: 1 };
  }

  next() {
    if (this.job.step === 1) {
      this.processInput();

      if (this.job.groups.length === 0)
        this.uiService.toast.error("Unable to process the input provided");
      else if (this.job.invalidStations.length > 0)
        this.uiService.toast.error(`The following station(s) are not active in Signal Manager: ${this.job.invalidStations.join(', ')}`);
      else if (this.job.duplicateStations.length > 0)
        this.uiService.toast.error(`The following station(s) appear more than once: ${this.job.duplicateStations.map(s => s.name).join(', ')}`);
      else
        this.job.step++;
    }
  }

  back() {
    if (this.job.step === 2) {
      this.job.step--;
    }
  }

  apply() {
    var adns = {};

    this.job.groups.forEach(group => {
      var adnId = group.adn.id || 0;
      adns[adnId] = adns[adnId] || [];
      group.stations.forEach(station => adns[adnId].push(station.id));
    });

    this.ref.close({ result: adns });
  }

  processInput() {
    this.job.groups = [];
    this.job.invalidStations = [];
    this.job.duplicateStations = [];

    var touchedStations = [];

    this.job.inputText.split('\n').forEach(row => {
      const [stationName, groupLabel] = row.split('\t');
      if (!stationName || !groupLabel) return;

      var station = this.stations.find(s => s.name === stationName);
      if (!station) {
        this.job.invalidStations.push(stationName);
        return;
      }

      if (touchedStations.some(s => s.id === station.id)) {
        this.job.duplicateStations.push(station);
      }

      touchedStations.push(station);

      const existingGroup = this.job.groups.find(g => g.title === groupLabel);

      if (existingGroup) {
        existingGroup.stations.push(station);
      } else {
        this.job.groups.push({
          title: groupLabel,
          stations: [station],
          adn: this.adns.find(x => x.name === groupLabel) || this.adns.find(x => !x.id)
        });
      }
    });
  }

  getStations() {
    this.apiService.get({
      method: "lookup/stations",
      onSuccess: (r) => {
        this.stations = r.result;
      }
    });
  }

  onTab(event: Event): void {
    event.preventDefault(); // prevent the default behavior of the tab key
    event.stopPropagation();

    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const value = textarea.value;
    textarea.value = value.substring(0, start) + '\t' + value.substring(end);
    textarea.selectionStart = textarea.selectionEnd = start + 1;
  }

  insertExampleText(event: Event) {
    event.preventDefault();

    this.job.inputText = "WCBS\tJacksonville @ Kansas City\nKCBS\tJacksonville @ Kansas City\nKCNC\tDenver @ Tennessee";
    this.textarea.nativeElement.focus();
  }
}

interface ImportJob {
  groups: Group[];
  inputText: string;
  invalidStations: string[];
  duplicateStations: Lookup[];
  step: number;
}

interface Group {
  title: string;
  stations: Lookup[];
  adn?: Lookup;
}

interface Lookup {
  id: number,
  name: string
}
