<ng-container *ngIf="station; else loading">
  <section>
    <div class="row">
      <div class="col-md-4">
        <label>Call Sign</label>
        <input type="text" pInputText [(ngModel)]="station.callSign" readonly />
      </div>
      <div class="col-md-4">
        <label>Location</label>
        <input type="text" pInputText [(ngModel)]="station.city" readonly />
      </div>
      <div class="col-md-4">
        <label>Time Zone</label>
        <input type="text" pInputText [(ngModel)]="station.ianaTimeZone" readonly />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label>Owner</label>
        <input type="text" pInputText [(ngModel)]="station.owner" readonly />
      </div>
      <div class="col-md-4">
        <label>Operator</label>
        <input type="text" pInputText [(ngModel)]="station.operator" readonly />
      </div>
    </div>
  </section>
  <section class="zixi-snapshots">
    <div class="flex-between">
      <h5>Live Snapshots</h5>
      <p-checkbox [(ngModel)]="autoRefresh" [binary]="true" label="Auto Refresh"></p-checkbox>
    </div>
    <span class="snapshots" *ngIf="platforms?.length">
      <span *ngFor="let platform of platforms; let isFirst = first" class="platform">
        <span class="info" [ngStyle]="{'margin-top.px': isFirst ? 25 : 0}">
          <span><label>Platform</label>{{platform.name}}</span>
          <span><label>Source</label>{{platform.source}}</span>
        </span>
        <span *ngFor="let location of locations" class="location">
          <span *ngIf="isFirst">{{location}}</span>
          <app-zixi-snapshot [stationId]="station.id" [platformId]="platform.id" [location]="location" [autoRefresh]="autoRefresh"></app-zixi-snapshot>
        </span>
      </span>
    </span>
  </section>
</ng-container>

<ng-template #loading>
  <div class="modal-loading">
    <i class="pi pi-spin pi-spinner"></i>
  </div>
</ng-template>
