import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { OverrideType } from '../../models/enums';
import { QueueJobComponent } from './queue-job.component';

@Injectable()
export class QueueJobService {
  constructor(
    private dialogService: DialogService) { }

  launchForOverride(actions: QueueJobActionData[], overrideType?: OverrideType) {
    let request: QueueJobRequest = {
      type: QueueJobType.Override,
      title: this.getTitle(overrideType),
      actions
    };

    actions.forEach(a => {
      a.apiMethod = `override/apply`;
      a.postData = {
        overrideTarget: { stationId: a.stationId, partnerId: a.partnerId },
        overrideType
      }
    });

    return this.launch(request);
  }

  launchForADN(actions: QueueJobActionData[]) {
    let request: QueueJobRequest = {
      type: QueueJobType.ADN,
      title: "ADN Request",
      actions
    };

    actions.forEach(a => {
      a.apiMethod = `adn/${a.adnId ? "connect" : "disconnect"}`;
      a.postData = {
        adnSwitchTarget: { stationId: a.stationId, targetId: a.targetId },
        switchToAdnId: a.adnId
      }
    });

    return this.launch(request);
  }

  launchForSourceSwitch(actions: QueueJobActionData[]) {
    let request: QueueJobRequest = {
      type: QueueJobType.ADN,
      title: "Source Switch Request",
      actions
    };

    actions.forEach(a => {
      a.apiMethod = `station/platform/source`;
      a.postData = {
        stationId: a.stationId,
        platformId: a.platformId,
        sourceId: a.sourceId
      }
    });

    return this.launch(request);
  }

  launch(request: QueueJobRequest) {
    return this.dialogService.open(QueueJobComponent, {
      width: '600px',
      showHeader: false,
      data: { request }
    });
  }

  private getTitle(overrideType?: OverrideType) {
    switch (overrideType) {
      case OverrideType.ForceBlackout: return "Force Blackout";
      case OverrideType.ForceStream: return "Force Stream";
      default: return "Run Schedule";
    };
  }
}

export interface QueueJobRequest {
  type: QueueJobType;
  actions: QueueJobActionData[];
  title: string
}

export interface QueueJobActionData {
  stationId: number;
  partnerId?: number;
  targetId?: number;
  platformId?: number;
  sourceId?: number;
  adnId?: number;
  description: string;
  apiMethod?: string;
  postData?: any;
}

export enum QueueJobType {
  Override = 1,
  ADN
}
