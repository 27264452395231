<ng-container *ngIf="job.step === 1">
  <p class="help">
    Enter one line for every station. Each line must contain the station's call sign, followed by a TAB and then a label that will be used for grouping the stations. <a href="" (click)="insertExampleText($event)">Click here</a> to see an example.
  </p>
  <textarea pInputTextarea [(ngModel)]="job.inputText" #textarea (keydown.tab)="onTab($event)"></textarea>
  <footer>
    <button pButton label="Next" class="p-button-sm p-button-outlined" (click)="next()" [disabled]="!job.inputText"></button>
  </footer>
</ng-container>
<ng-container *ngIf="job.step === 2">
  <section class="groups">
    <span class="help">Please specify ADN assignments for the following:</span>
    <div *ngFor="let group of job.groups">
      <div class="flex-between">
        <h6>{{ group.title }}</h6>
        <p-dropdown [options]="adns" [(ngModel)]="group.adn" optionLabel="name" appendTo="body"></p-dropdown>
      </div>
      <div class="station-list">
        <div *ngFor="let station of group.stations">{{ station.name }}</div>
      </div>
    </div>
  </section>
  <footer class="between">
    <button pButton label="Back" class="p-button-sm p-button-outlined" (click)="back()"></button>
    <button pButton label="Apply" class="p-button-sm p-button-outlined" (click)="apply()"></button>
  </footer>
</ng-container>
