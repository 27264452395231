import { Injectable, Type } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AiringSummaryComponent } from '../../airing/airing-summary/airing-summary.component';
import { StationSummaryComponent } from '../../station/station-summary/station-summary.component';
import { UserDetailComponent } from '../../system/user/user-detail/user-detail.component';

@Injectable()
export class UiService {
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService) { }

  config = {
    showSpinner: false
  };

  startSpinner() {
    setTimeout(() => {
      this.config.showSpinner = true;
    });
  }

  stopSpinner() {
    setTimeout(() => {
      this.config.showSpinner = false;
    });
  }

  toast = {
    add: (severity: string, summary: string, detail?: string) => {
      this.messageService.add({ key: 'appToast', severity, summary, detail });
    },
    success: (summary: string, detail?: string) => {
      this.toast.add("success", summary, detail);
    },
    error: (summary: string, detail?: string) => {
      this.toast.add("error", summary, detail);
    },
    info: (summary: string, detail?: string) => {
      this.toast.add("info", summary, detail);
    }
  }

  handleUnexpectedError = (message?: string) => {
    this.toast.error("An unexpected error has occurred");
  }

  isSysAdmin() {
    // TODO: Implement - for driving various UI logic
    return true;
  }

  open = {
    dialog: (componentType: Type<any>, config: DynamicDialogConfig) => {
      return this.dialogService.open(componentType, config);
    },
    airing: (id: number) => {
      return this.dialogService.open(AiringSummaryComponent, {
        header: 'Airing',
        width: '1200px',
        data: { airingId: id },
      });
    },
    station: (id: number) => {
      this.dialogService.open(StationSummaryComponent, {
        header: 'Station',
        width: '770px',
        data: { stationId: id },
      });
    },
    user: (id: number, header: string) => {
      return this.dialogService.open(UserDetailComponent, {
        header,
        width: '900px',
        data: { appUserId: id },
        closable: false
      });
    }
  }
}
