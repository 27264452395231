import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../_shared/services/api.service';
import { DateTime } from 'luxon';
import { UiService } from '../../_shared/services/ui.service';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../../_shared/services/auth.service';
import { AppPermission } from '../../_shared/models/user';

@Component({
  selector: 'app-blackout-rules',
  templateUrl: './blackout-rules.component.html',
  styleUrls: ['./blackout-rules.component.scss']
})
export class BlackoutRulesComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authService: AuthService,
    private confirmationService: ConfirmationService) { }

  options: any = {};
  selected;
  rules;
  ui: any = { block: false, canEdit: false };

  ngOnInit() {
    this.ui.canEdit = this.authService.isPermitted(AppPermission.mngStnSubRules);

    this.getRules();
    this.getOptions();
  }

  addRule() {
    this.selected = { _isNew: true, active: true, startDate: DateTime.now().toJSDate() };

    // clear dependent dropdowns
    this.stationChanged();
  }

  editRule(r) {
    var dt1 = DateTime.fromISO(r.airingsAfterDate);
    var dt2 = DateTime.fromISO(r.airingsBeforeDate);

    this.selected = {
      signalEventRuleId: r.signalEventRuleId,
      stationId: r.stationId,
      partnerIds: r.partners.map(x => x.partnerId),
      titleToMatch: r.titleToMatch,
      startDate: dt1.isValid ? dt1.toJSDate() : null,
      endDate: dt2.isValid ? dt2.toJSDate() : null,
      active: r.active
    };

    this.stationChanged();
  }

  closeDialog() {
    this.selected = undefined;
  }

  getRules() {
    var params:any = {};
    if (this.ui.stationId)
      params.stationId = this.ui.stationId;

    this.apiService.get({
      method: "signalEventRulesEngine",
      params,
      blockUi: true,
      onSuccess: (r) => {
        this.rules = r.result;
      }
    });
  }

  saveRule() {
    var dt1 = DateTime.fromJSDate(this.selected.startDate);
    var dt2 = DateTime.fromJSDate(this.selected.endDate);

    if (!dt1.isValid) {
      this.uiService.toast.error("Invalid start date");
      return;
    }

    var data: any = {
      name: "###",
      active: this.selected.active,
      titleToMatch: this.selected.titleToMatch,
      partners: this.selected.partnerIds,
      airingsAfterDate: dt1.toISODate(),
      airingsBeforeDate: dt2.isValid ? dt2.toISODate() : null
    };

    var method = `signalEventRulesEngine`;

    if (this.selected.signalEventRuleId) {
      method = `${method}/update`;
      data.signalEventRuleId = this.selected.signalEventRuleId;
      data.stationId = this.selected.stationId;
    } else {
      method = `${method}/create`;
      data.stations = this.selected.stationIds;
    }

    this.apiService.post({
      method, data,
      blockUi: true,
      onSuccess: (r) => {
        this.getRules();
        this.closeDialog();
      }
    });
  }

  deleteRule(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure? Any future blackouts created by this rule will be removed.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.ui.block = true;
        this.apiService.delete({
          method: "signalEventRulesEngine",
          params: { ruleId: this.selected.signalEventRuleId },
          blockUi: true,
          onSuccess: (r) => {
            this.ui.block = false;
            this.getRules();
            this.closeDialog();

            if (r.result.signalEventsDeleted > 0)
              this.uiService.toast.info(`${r.result.signalEventsDeleted} blackouts have been removed`);
          }
        });
      }
    });
  }

  getOptions() {
    this.apiService.get({
      method: "lookup/stations",
      onSuccess: (r) => {
        this.options.stations = r.result;
      }
    });

    this.options.minDate = DateTime.now().toJSDate();
    this.options.maxDate = DateTime.local(2100, 12, 31).toJSDate();
  }

  stationChanged() {
    this.options.partners = [];
    this.options.titles = [];

    var stationIds = this.selected._isNew ? this.selected.stationIds : [this.selected.stationId];
    if (!stationIds?.length) return;

    this.apiService.post({
      method: "signalEventRulesEngine/options",
      data: { stationIds },
      onSuccess: (r) => {
        this.options.titles = r.result.titles.map(x => ({ title: x }));
        this.options.partners = r.result.partners;

        //this.selected.partnerIds = []; // TODO: REMOVE ANY PARTNERS THAT ARE NOT IN THE NEW LIST
      }
    });
  }

  runNow(rule) {
    this.ui.block = true;
    this.apiService.put({
      method: `signalEventRulesEngine/run`,
      data: { stationId: rule.stationId, signalEventRuleId: rule.signalEventRuleId },
      onSuccess: (r) => {
        this.ui.block = false;

        var results = r.result.ruleExecutionResults;
        if (results.length === 1) {
          var count = results[0].signalEventsCreated;

          var msg = count === 0
            ? `ran successfully but no new blackouts were created`
            : `resulted in the creation of ${count} new blackouts`;

          this.uiService.toast.success(`Rule ${rule.signalEventRuleId} ${msg}`);
        }
      }
    });
  }
}
