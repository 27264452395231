import { Component } from '@angular/core';
import { AppPermission } from '../../_shared/models/user';
import { ApiService } from '../../_shared/services/api.service';
import { AuthService } from '../../_shared/services/auth.service';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService) { }

  stations: any[];
  settings = { showHidden: false, canEdit: false }

  ngOnInit() {
    this.settings.canEdit = this.authService.isPermitted(AppPermission.mngStations);

    this.getStations();
  }

  getStations() {
    this.apiService.get({
      method: "station/admin",
      onSuccess: (r) => { this.stations = r.result; }
    });
  }
}
