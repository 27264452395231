import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../_shared/services/api.service';
import { UiService } from '../../_shared/services/ui.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-blackout-list',
  templateUrl: './blackout-list.component.html',
  styleUrls: ['./blackout-list.component.scss']
})
export class BlackoutListComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private route: ActivatedRoute) { }

  results: any = [];
  config: any = {};

  ngOnInit() {
    this.apiService.get({
      method: "partner",
      onSuccess: (r) => {
        this.config.partners = r.result;
      }
    });

    this.refresh();
  }

  refresh() {
    var params: any = {};

    var ruleId = parseInt(this.route.snapshot.queryParams['rule']);

    if (ruleId > 0) {
      params.ruleId = ruleId;

      this.apiService.get({
        method: "signalEventRulesEngine",
        params,
        blockUi: true,
        onSuccess: (r) => {
          if (r.result.length === 1) {
            this.config.rule = r.result[0];
            this.config.rule.partnersCsv = this.config.rule.partners.map(p => p.name).join(", ");

            var dt1 = DateTime.fromISO(this.config.rule.airingsAfterDate);  
            var dt2 = DateTime.fromISO(this.config.rule.airingsBeforeDate);            
            this.config.rule.dates = dt1.toFormat("D") + (dt2.isValid ? ` - ${dt2.toFormat("D")}` : "+");; 
          }
        }
      });
    }

    this.apiService.get({
      method: "signalEvent/schedule",
      blockUi: true,
      params,
      onSuccess: (r) => {
        this.results = r.result;
      }
    });
  }

  openAiring($event, airingId) {
    var dialog = this.uiService.open.airing(airingId);

    dialog.onClose.subscribe(r => {
      if (r?.refresh === true) {
        this.refresh();
      }
    });

    $event.stopPropogation();
  }
}
