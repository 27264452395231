<div class="station-picker-overlay">
  <h5>Select stations</h5>
  <div class="search">
    <span class="p-input-icon-right">
      <i class="pi pi-times" (click)="clearText()" *ngIf="searchText"></i>
      <input type="text" pInputText [(ngModel)]="searchText" placeholder="Enter call sign to add station"
             (keyup)="findMatches()" (keydown.enter)="selectFirst()" [style]="{width:'240px'}" autofocus
             class="p-inputtext-sm">
    </span>
    <span class="search-match" *ngIf="singleMatch">
      Press ENTER to add <strong>{{singleMatch.name}}</strong>
    </span>
  </div>
  <div class="station-list" [ngStyle]="{height: scrollHeight}">
    <div *ngFor="let station of matches" class="station"
         (click)="selectStation(station)">
      {{station.name}}
    </div>
  </div>
</div>
