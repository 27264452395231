import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiRequestConfig, ApiResponse } from '../models/models';
import { UiService } from './ui.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private uiService: UiService) { }

  get(config: ApiRequestConfig) {
    var request = this.http.get<ApiResponse>(this.getUrl(config), { params: config.params });

    this.executeRequest(request, config);
  }

  post(config: ApiRequestConfig) {
    var request = this.http.post<ApiResponse>(this.getUrl(config), config.data);

    this.executeRequest(request, config);
  }

  put(config: ApiRequestConfig) {
    var request = this.http.put<ApiResponse>(this.getUrl(config), config.data);

    this.executeRequest(request, config);
  }

  delete(config: ApiRequestConfig) {
    var request = this.http.delete<ApiResponse>(this.getUrl(config), { params: config.params });

    this.executeRequest(request, config);
  }

  getMultiple(urls: string[]) {
    var get = (path: string) => (this.http.get<ApiResponse>(`${environment.apiUrl}/v1/${path}`));

    return forkJoin(urls.map(url => { return get(url) }));
  }

  getUrl(config: ApiRequestConfig) {
    config.apiVersion = config.apiVersion || "v1";

    return `${environment.apiUrl}/${config.apiVersion}/${config.method}`;
  }

  private executeRequest(request: Observable<ApiResponse>, config: ApiRequestConfig) {
    if (config.blockUi)
      this.uiService.startSpinner();

    request.subscribe(r => {
      if (config.blockUi)
        this.uiService.stopSpinner();

      if (r.success) {
        if (config.onSuccess) {
          config.onSuccess(r);
        }
      } else if (r.errors?.length) {
        if (config.onError) {
          config.onError(r);
        } else {
          r.errors.forEach((error: any) => {
            this.uiService.toast.error(`Error ${error.code}`, error.description)
          });
        }
      }
    }, this.handleError);
  }

  private handleError = (response: HttpErrorResponse) => {
    let text = "An unexpected error has occurred.";
    var toastGenericResponse = true;

    switch (response.status) {
      case 400:
        if (response?.error?.title === "One or more validation errors occurred." && response.error.errors) {
          for (const key in response.error.errors) {
            var errorText = response.error.errors[key];
            if (typeof errorText[0] === 'string') {
              this.uiService.toast.error(errorText);
              toastGenericResponse = false;
            }
          }
        } else if (response.error.message) {
          this.uiService.toast.error(response.error.message);
          toastGenericResponse = false;
        }
        break;
      case 401:
      case 403:
        text = "You are not authorized to perform this action.";
        break;
      case 404:
        text = "Invalid request."
        break;
      default:
        if (response?.error?.message) {
          this.uiService.toast.error(response.error.message);
          toastGenericResponse = false;
        }
        break;
    }

    if (toastGenericResponse)
      this.uiService.toast.error(`Error - ${response.status}`, text);
  }
}
