<header>
  <h4 class="title">{{job.title}}</h4>
  <div class="status-bar" *ngIf="job.started && !job.completed && !job.cancelled">
    <h6>{{job.completeCount}} of {{job.size}} completed</h6>
    <p-progressBar [value]="job.progress" [showValue]="false" [style]="{height: '8px'}"></p-progressBar>
  </div>
</header>
<section class="actions">
  <div *ngFor="let action of job.actions" class="action">
    <div class="icon">
      <i *ngIf="action.started && !action.completed" class="pi pi-spin pi-spinner"></i>
      <i *ngIf="action.success === false" class="pi pi-exclamation-triangle"></i>
      <i *ngIf="action.success === true" class="pi pi-check"></i>
    </div>
    <div>
      <h6>{{action.description}}</h6>
      <div *ngIf="action.completed" class="message" [ngClass]="{ error: !action.success }">
        {{action.message}}
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="buttons">
    <button pButton label="Stop All" class="p-button-sm p-button-outlined"
            (click)="stopJob()"
            *ngIf="job.started && !job.completed && !job.cancelled"
            [disabled]="job.completed || job.cancelled"></button>
    <button pButton label="Close" class="p-button-sm p-button-outlined"
            (click)="closeDialog()"
            [disabled]="job.started && !job.completed && !job.cancelled"></button>
  </div>
</footer>
