<span (click)="userOverlay.toggle($event)" style="cursor: pointer">
  <i class="pi pi-user"></i> {{user.firstName}} {{user.lastName}}
</span>
<p-overlayPanel #userOverlay [dismissable]="true">
  <section class="user-menu">
    <h6>Signal Manager</h6>
    <span><label>Environment:</label> {{env}}</span>
    <span><label>Version:</label> {{version}}</span>
    <span class="user-info">
      <span>Logged in as {{user.oktaLogin}}</span>
      <button pButton label="Logout" class="p-button-sm p-button-outlined" (click)="signOut()"></button>
    </span>
  </section>
</p-overlayPanel>
