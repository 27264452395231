import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-zixi-snapshot',
  templateUrl: './zixi-snapshot.component.html',
  styleUrls: ['./zixi-snapshot.component.scss']
})
export class ZixiSnapshotComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private apiService: ApiService,
    private http: HttpClient) { }

  @Input() stationId: number;
  @Input() platformId: number;
  @Input() location: string;
  @Input() size: string;
  @Input() autoRefresh: boolean;
  imageSrc: string;
  loading: boolean;
  success: boolean;
  failed: boolean;
  errorMessage: string;
  apiUrl: string;
  refreshTimer: Subscription;
  refreshCount = 0;
  settings = { refreshInterval: 10000, maxRefreshes: 100 };

  ngOnInit() {
    this.apiUrl = this.apiService.getUrl({ method: `Zixi/getZixiTargetPreview/${this.location}` });

    if (this.autoRefresh)
      this.startTimer();
    else
      this.getImage();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer() {
    this.stopTimer();
    this.refreshTimer = timer(0, this.settings.refreshInterval).subscribe(() => {
      this.getImage();
      this.refreshCount++;

      if (this.refreshCount === this.settings.maxRefreshes)
        this.stopTimer();
    });
  }

  stopTimer() {
    this.refreshCount = 0;

    if (this.refreshTimer)
      this.refreshTimer.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    var change: SimpleChange = changes.autoRefresh;
    if (change && !change.firstChange) {
      if (change.currentValue === true)
        this.startTimer();
      else
        this.stopTimer();
    }
  }

  getImage() {
    if (this.loading) return;

    this.failed = false;
    this.loading = true;
    this.errorMessage = undefined;
    var params = { stationId: this.stationId, platformId: this.platformId };

    this.http.get(this.apiUrl, { params, responseType: 'blob' })
      .subscribe((response: Blob) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          this.imageSrc = reader.result as string;
          this.loading = false;
          this.success = true;
        };

        reader.readAsDataURL(response);
      }, this.handleError);
  }

  handleError = (error: HttpErrorResponse) => {
    this.failed = true;
    this.success = false;
    this.loading = false;

    const reader = new FileReader();

    reader.onloadend = (e) => {
      var response = JSON.parse(reader.result as string);
      this.errorMessage = response?.message || "An error has occurred";
    }

    reader.readAsText(error.error);
  }

  getStyleClass() {
    var style = {
      loading: this.loading,
      failed: this.failed,
      success: this.success
    };

    if (this.size)
      style[this.size] = true;

    return style;
  }
}
