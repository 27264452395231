import { Component, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiService } from '../../_shared/services/api.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  constructor(
    private apiService: ApiService) { }

  @ViewChild(OverlayPanel) private op: OverlayPanel;
  result: ActivitySearchResult;
  entries: ActivityEntry[];
  selected: ActivityEntry;
  totalRecords: number;
  loading: boolean = true;
  options: any = {};
  
  ngOnInit() {
    this.getOptions();
  }

  loadResult(event: LazyLoadEvent) {
    this.loading = true;

    var params = {
      pageSize: event.rows,
      pageNumber: (event.first / event.rows) + 1,
      sortBy: event.sortField,
      sortDirection: event.sortOrder === 1 ? "desc" : "asc",
      activityTypeId: event.filters.activityType?.value?.activityTypeId,
      appUserOktaId: event.filters.user?.value || undefined,
      stationId: event.filters.station?.value?.id,
      platformId: event.filters.platform?.value?.id,
      audienceId: event.filters.audience?.value?.id,
      performedOnUtc: event.filters.performedOnUTC?.value
        ? DateTime.fromJSDate(event.filters.performedOnUTC.value).toISODate()
        : undefined,
      status: event.filters.status?.value?.id
    };

    // Remove undefined filters
    Object.keys(params).forEach(key => params[key] === undefined ? delete params[key] : {});

    this.apiService.get({
      method: "activity",
      params,
      onSuccess: (r) => {
        this.result = r.result;
        this.entries = this.result.items;
        this.totalRecords = this.result.totalCount;
        this.loading = false;
      }
    });
  }

  showDetails($event, entry: ActivityEntry) {
    this.selected = entry;
    this.op.show($event);

    return false;
  }

  getOptions() {
    this.apiService.get({
      method: "lookup/activityTypes",
      onSuccess: (r) => {
        this.options.activityTypes = r.result;
      }
    });

    this.apiService.get({
      method: "lookup/stations",
      onSuccess: (r) => {
        this.options.stations = r.result;
      }
    });

    this.apiService.get({
      method: "lookup/platforms",
      onSuccess: (r) => {
        this.options.platforms = r.result;
      }
    });

    this.apiService.get({
      method: "lookup/audiences",
      onSuccess: (r) => {
        this.options.audiences = r.result;
      }
    });

    this.options.statuses = [{ id: -1, name: "Failed" }, { id: 0, name: "Unknown" }, { id: 1, name: "Success" }];
  }
}

interface ActivitySearchResult {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  items: ActivityEntry[];
}

interface ActivityEntry {
  activityType?: string;
  user?: string;
  performedOnUTC?: DateTime;
  stationId?: number;
  platformId?: number;
  activityLogDetail?: ActivityEntryDetail[];
}

interface ActivityEntryDetail {
  name: string;
  value: string;
}
