<ng-container *ngIf="user; else loading">
  <section>
    <form #userForm="ngForm" (ngSubmit)="save()" id="myForm">
      <div class="row">
        <div class="col-md-3">
          <label>First Name</label>
          <input name="firstName" pInputText type="text" [(ngModel)]="user.firstName" [disabled]="!canEdit" maxlength="225" required>
        </div>
        <div class="col-md-3">
          <label>Last Name</label>
          <input name="lastName" pInputText type="text" [(ngModel)]="user.lastName" [disabled]="!canEdit" maxlength="225" required>
        </div>
        <div class="col-md-3">
          <label>Login</label>
          <input name="oktaLogin" pInputText type="text" [(ngModel)]="user.oktaLogin" [disabled]="!canEdit" maxlength="225" required>
        </div>
        <div class="col-md-3" style="align-self: end; margin-bottom: 10px">
          <p-checkbox name="active" [(ngModel)]="user.active" [binary]="true" [disabled]="!canEdit" label="Active"></p-checkbox>
        </div>
      </div>
    </form>
  </section>
  <section>
    <div class="flex">
      <h5>System Roles</h5>
      <p-multiSelect [options]="options.systemRoles" [(ngModel)]="user.systemRoles" optionLabel="name" optionValue="roleId" display="chip" [disabled]="!canEdit" *ngIf="options"></p-multiSelect>
    </div>
  </section>
  <section>
    <div class="flex-between">
      <h5>Station Roles</h5>
      <input pInputText type="text" [(ngModel)]="stationFilter" placeholder="Filter by Station" (input)="filterStations()"
             style="width: 200px; margin-bottom: 10px" />
    </div>
    <div class="station-roles">
      <p-table #table
               [value]="options.stations"
               *ngIf="options"
               styleClass="p-datatable-gridlines"
               sortField="name"
               [scrollable]="true"
               scrollHeight="flex"
               [globalFilterFields]="['name']">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">Station <p-sortIcon field="name"></p-sortIcon></th>
            <th *ngFor="let role of options.stationRoles" class="text-center" pSortableColumn="{{'role' + role.roleId}}">
              {{role.name}} <p-sortIcon field="{{'role' + role.roleId}}"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th>&nbsp;</th>
            <th *ngFor="let role of options.stationRoles" class="text-center">
              <p-checkbox [(ngModel)]="role.all" [binary]="true" (onChange)="selectAllNone(role)" [disabled]="!canEdit"
                          [pTooltip]="role.all ? 'Select None' : 'Select All'"></p-checkbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-station>
          <tr>
            <td>{{station.name}}</td>
            <td *ngFor="let role of options.stationRoles" class="text-center">
              <p-checkbox [binary]="true" [(ngModel)]="station['role' + role.roleId]" (onChange)="selectionChanged(role)" [disabled]="!canEdit"></p-checkbox>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </section>
  <footer>
    <button pButton class="p-button-sm p-button-outlined" label="Save" type="submit" [disabled]="!userForm.valid" form="myForm"></button>
    <button pButton class="p-button-sm p-button-outlined" label="Cancel" (click)="close()"></button>
  </footer>
</ng-container>

<ng-template #loading>
  <div class="modal-loading">
    <i class="pi pi-spin pi-spinner"></i>
  </div>
</ng-template>
