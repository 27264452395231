import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// PrimeNG
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip'

// Other
import { AuthService } from './_shared/services/auth.service';
import { UiService } from './_shared/services/ui.service';
import { ApiService } from './_shared/services/api.service';
import { QueueJobService } from './_shared/components/queue-job/queue-job.service';
import { HttpsGuard, PermissionsGuard } from './_shared/functions/routing';
import { AppPermission } from './_shared/models/user';
import { environment } from '../environments/environment';

// Okta
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AuthInterceptor } from './_shared/interceptors/auth.interceptor';

// App Components
import { ActivityLogComponent } from './system/activity-log/activity-log.component';
import { AdnImportComponent } from './adn/adn-import/adn-import.component';
import { AdnMapperComponent } from './adn/adn-mapper/adn-mapper.component';
import { AiringScheduleComponent } from './airing/airing-schedule/airing-schedule.component';
import { AiringScheduleFilterPipe } from './airing/airing-schedule/airing-schedule.pipe';
import { AiringSummaryComponent } from './airing/airing-summary/airing-summary.component';
import { AppComponent } from './app.component';
import { BlackoutListComponent } from './blackout/blackout-list/blackout-list.component';
import { BlackoutRulesComponent } from './blackout/blackout-rules/blackout-rules.component';
import { CacheManagerComponent } from './system/cache-manager/cache-manager.component';
import { LoggedOutComponent } from './_shared/components/logged-out/logged-out.component';
import { OverrideGroupsComponent } from './override/override-groups/override-groups.component';
import { QueueJobComponent } from './_shared/components/queue-job/queue-job.component';
import { StationDetailComponent } from './station/station-detail/station-detail.component';
import { StationListComponent } from './station/station-list/station-list.component';
import { StationOverviewComponent } from './station/station-overview/station-overview.component';
import { StationPickerComponent } from './_shared/components/station-picker/station-picker.component';
import { StationSummaryComponent } from './station/station-summary/station-summary.component';
import { UnauthorizedComponent } from './_shared/components/unauthorized/unauthorized.component';
import { UserDetailComponent } from './system/user/user-detail/user-detail.component';
import { UserListComponent } from './system/user/user-list/user-list.component';
import { UserMenuComponent } from './system/user/user-menu/user-menu.component';
import { ZixiSnapshotComponent } from './_shared/components/zixi-snapshot/zixi-snapshot.component';


const oktaAuth = new OktaAuth({
  issuer: `${environment.oktaIssuer}`,
  clientId: `${environment.oktaClientId}`,
  redirectUri: `${environment.oktaRedirectUri}`,
  postLogoutRedirectUri: `${environment.oktaPostLogoutRedirectUri}`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});


@NgModule({
  declarations: [
    ActivityLogComponent,
    AdnImportComponent,
    AdnMapperComponent,
    AiringScheduleComponent,
    AiringScheduleFilterPipe,
    AiringSummaryComponent,
    AppComponent,
    BlackoutListComponent,
    BlackoutRulesComponent,
    CacheManagerComponent,
    LoggedOutComponent,
    OverrideGroupsComponent,
    QueueJobComponent,
    StationDetailComponent,
    StationListComponent,
    StationOverviewComponent,
    StationPickerComponent,
    StationSummaryComponent,
    UnauthorizedComponent,
    UserDetailComponent,
    UserListComponent,
    UserMenuComponent,
    ZixiSnapshotComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    OktaAuthModule,
    RouterModule.forRoot([
      {
        path: '',
        canActivateChild: [HttpsGuard, OktaAuthGuard],
        children: [
          { path: 'schedule', component: AiringScheduleComponent, canActivate: [() => PermissionsGuard(AppPermission.vwStnSchedule)] },
          { path: 'schedule-overrides', component: OverrideGroupsComponent, canActivate: [() => PermissionsGuard(AppPermission.vwOverrideGrp)] },
          { path: 'blackout-schedule', component: BlackoutListComponent, canActivate: [() => PermissionsGuard(AppPermission.vwStnSchedule)] },
          { path: 'blackout-rules', component: BlackoutRulesComponent, canActivate: [() => PermissionsGuard(AppPermission.vwStnSubRules)] },
          { path: 'adn-mapper', component: AdnMapperComponent, canActivate: [() => PermissionsGuard(AppPermission.vwADNMap)] },
          { path: 'activity-log', component: ActivityLogComponent, canActivate: [() => PermissionsGuard(AppPermission.vwSysSettings)] },
          { path: 'cache-manager', component: CacheManagerComponent, canActivate: [() => PermissionsGuard(AppPermission.vwSysSettings)] },
          { path: 'users', component: UserListComponent, canActivate: [() => PermissionsGuard(AppPermission.vwUser)] },
          { path: 'station-overview', component: StationOverviewComponent, canActivate: [() => PermissionsGuard(AppPermission.vwSourceCon)] },
          {
            path: 'stations',
            canActivate: [() => PermissionsGuard(AppPermission.vwStations)],
            children: [
              { path: '', component: StationListComponent },
              { path: ':id', component: StationDetailComponent }
            ]
          },
          { path: '', redirectTo: '/schedule', pathMatch: 'full' }
        ]
      },
      // Components without AuthGuard
      { path: 'implicit/callback', component: OktaCallbackComponent },
      { path: 'logged-out', component: LoggedOutComponent },
      { path: 'unauthorized', component: UnauthorizedComponent }
    ]),

    // PrimeNG modules
    BlockUIModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ContextMenuModule,
    DialogModule,
    DividerModule,
    DragDropModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MenubarModule,
    MenuModule,
    MessageModule,
    MultiSelectModule,
    OverlayPanelModule,
    ProgressBarModule,
    SidebarModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
    ApiService,
    AuthService,
    UiService,
    QueueJobService,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
