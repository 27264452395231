import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, from, map, mergeMap, of } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { QueueJobRequest } from './queue-job.service';

@Component({
  selector: 'app-queue-job',
  templateUrl: './queue-job.component.html',
  styleUrls: ['./queue-job.component.scss']
})
export class QueueJobComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  request: QueueJobRequest;
  job = {
    title: null,
    batchSize: 5,
    actions: [],
    size: 0,
    completeCount: 0,
    progress: 0,
    started: false,
    completed: false,
    cancelled: false
  };

  ngOnInit() {
    this.request = this.config.data.request;

    this.initJob();
    this.execJob();
  }

  initJob() {
    this.job = {
      ...this.job,
      title: this.request.title,
      size: this.request.actions.length
    }

    this.job.actions = this.request.actions
      .map(a => ({
        ...a,
        response: null,
        error: null
      }));
  }

  execJob() {
    this.job.started = true;

    from(this.job.actions)
      .pipe(mergeMap((action) => this.execAction(action), this.job.batchSize))
      .subscribe();
  }

  execAction(action) {
    if (this.job.cancelled) {
      action.completed = true;
      action.message = "Cancelled";
      return of(action);
    }

    action.started = true;

    var apiUrl = this.apiService.getUrl({ method: action.apiMethod });

    return this.http.post(apiUrl, action.postData)
      .pipe(
        map((response: any) => this.handleReponse(response, action)),
        catchError(response => this.handleReponse(response, action))
      );
  }

  handleReponse(response, action) {
    action.response = response;
    action.completed = true;
    action.success = response.success === true ? true : false;
    action.message = action.success ? "Success" : (response.error?.message || "An error has occurred");

    this.job.completeCount++;
    this.job.progress = Math.ceil((this.job.completeCount * 100) / this.job.size);

    if (this.job.completeCount === this.job.size)
      this.job.completed = true;

    return of(response);
  }

  stopJob() {
    this.job.cancelled = true;
  }

  closeDialog() {
    var stationIds = this.job.actions.filter(a => a.completed).map(a => a.stationId);
    stationIds = Array.from(new Set(stationIds));

    this.ref.close({ refresh: true, stationIds });
  }
}
