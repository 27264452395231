<!-- Filters -->
<div class="filters">
  <p-calendar [(ngModel)]="settings.jsDate" (onSelect)="dateChanged()" [minDate]="settings.minDate" [maxDate]="settings.maxDate" [readonlyInput]="true"></p-calendar>
  <input type="text" pInputText placeholder="Search" [(ngModel)]="filters.search" class="search" (keyup)="searchChanged()">
  <p-multiSelect [options]="options.stations" [(ngModel)]="filters.stations" optionLabel="name" defaultLabel="Select a Station" [showClear]="true"></p-multiSelect>
  <p-multiSelect [options]="options.airings" [(ngModel)]="filters.airings" defaultLabel="Select an Airing" [showClear]="true"
                 optionLabel="title" optionValue="title" [virtualScroll]="true" [virtualScrollItemSize]="50" scrollHeight="500px" appendTo="body"
                 [style]="{width:'500px'}"></p-multiSelect>
  <p-dropdown [options]="options.timelines" [(ngModel)]="settings.timeline" (onChange)="dateChanged()"
              optionLabel="name" optionValue="id"></p-dropdown>
</div>

<!-- Schedule -->
<div class="schedule" #scrollable [ngClass]="{searching: settings.searchEnabled}" oncontextmenu="return false;">
  <div class="date">
    <span class="skip" (click)="skipDays(-1)" pTooltip="{{settings.prevDay|date:'shortDate'}}" [showDelay]="500">
      <i class="pi pi-angle-double-left"></i>
    </span>
    <span pTooltip="{{settings.jsDate|date:'fullDate'}}" [showDelay]="500">{{settings.jsDate|date:'EEE M/d'}}</span>
    <span class="skip" (click)="skipDays(1)" pTooltip="{{settings.nextDay|date:'shortDate'}}" [showDelay]="500">
      <i class="pi pi-angle-double-right"></i>
    </span>
  </div>
  <div class="timeline">
    <div class="live-bar" [ngStyle]="{width: settings.liveBarPos + 'px'}" *ngIf="settings.showLive"></div>
    <div class="live-point" [ngStyle]="{left: (settings.liveBarPos - 6) + 'px'}" *ngIf="settings.showLive"></div>
    <div *ngFor="let block of results.blocks" class="timeblock" [ngClass]="{alt: block.min % 30 !== 0}">
      <span class="tick"></span>
      <span class="time">{{block.text}}</span>
    </div>
    <div class="timeblock next-day" (click)="skipDays(1)">
      <span class="tick"></span>
      <span class="time" pTooltip="{{settings.nextDay|date:'shortDate'}}" [showDelay]="500">{{settings.nextDay|date:'EEE M/d'}} <i class="pi pi-angle-double-right"></i></span>
    </div>
  </div>
  <div class="stations">
    <div *ngFor="let station of results.stations | airingScheduleFilter:filters">
      <div class="lineup">
        <div class="label"
             (click)="toggleExpand(station)"
             (contextmenu)="menu.station($event, station)">
          <i class="pi pi-exclamation-circle" *ngIf="!station.partners.length"></i>
          <span>
            {{station.callSign}}
            <small>{{station.city}}</small>
          </span>
        </div>
        <div class="events">
          <div *ngFor="let airing of station.airings" class="airing"
               [ngStyle]="airing.style"
               [ngClass]="airing.class"
               (contextmenu)="menu.airing($event, station, airing)">
            {{airing.title}}
          </div>
        </div>
      </div>
      <div class="partners" *ngIf="station.expand">
        <div *ngFor="let partner of station.partners" class="lineup"
             [ngClass]="partner.class">
          <div class="label"
               (contextmenu)="menu.partner($event, station, partner)">{{partner.name}}</div>
          <div class="events">
            <div *ngFor="let airing of station.airings" class="airing"
                 [ngStyle]="airing.style"
                 [ngClass]="airing.partnerClass[partner.id]"
                 (contextmenu)="menu.airing($event, station, airing, partner)">
              {{airing.title}}
            </div>
            <div *ngFor="let blackout of partner.blackouts" class="blackout"
                 (contextmenu)="menu.blackout($event, station, blackout)"
                 [ngStyle]="blackout.style"
                 [ngClass]="blackout.class">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Misc components -->
<p-blockUI [blocked]="settings.block">
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-contextMenu #contextMenu appendTo="body" [model]="contextMenuItems"></p-contextMenu>
