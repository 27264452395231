<div class="page-header">
  <h3 class="title">Station Overview</h3>
  <div class="button-group" *ngIf="entries && canEdit">
    <button pButton label="Switch Source" class="p-button-sm p-button-outlined" (click)="newJob()" *ngIf="selection.any"></button>
    <button pButton label="Refresh" class="p-button-sm p-button-outlined" (click)="refresh()"></button>
  </div>
</div>
<div class="page-body">
  <p-table #table
           [value]="entries"
           [scrollable]="true"
           scrollHeight="flex"
           (onFilter)="onFilter($event)">
    <ng-template pTemplate="header">
      <tr>
        <th [hidden]="!canEdit">
          <p-checkbox [(ngModel)]="selection.all" [binary]="true" (onChange)="selectAllNone()"></p-checkbox>
        </th>
        <th pSortableColumn="station">Station <p-sortIcon field="station"></p-sortIcon></th>
        <th pSortableColumn="platform">Platform <p-sortIcon field="platform"></p-sortIcon></th>
        <th pSortableColumn="source">Source <p-sortIcon field="source"></p-sortIcon></th>
        <th pSortableColumn="timeZone">Time Zone <p-sortIcon field="timeZone"></p-sortIcon></th>
      </tr>
      <tr>
        <th></th>
        <th>
          <p-columnFilter field="stationId" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="filters.stations"
                             (onChange)="filter($event.value)" (onClear)="filter()" placeholder="All" optionLabel="name" optionValue="id" [showClear]="true"></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="platformId" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="filters.platforms"
                             (onChange)="filter($event.value)" (onClear)="filter()" placeholder="All" optionLabel="name" optionValue="id" [showClear]="true"></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="sourceId" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="filters.sources"
                             (onChange)="filter($event.value)" (onClear)="filter()" placeholder="All" optionLabel="name" optionValue="id" [showClear]="true"></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="timeZone" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="filters.timeZones"
                             (onChange)="filter($event.value)" (onClear)="filter()" placeholder="All" [showClear]="true"></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entry>
      <tr>
        <td [hidden]="!canEdit">
          <p-checkbox [(ngModel)]="entry.selected" [binary]="true" (onChange)="selectionChanged()"></p-checkbox>
        </td>
        <td>
          <a href="" (click)="openStation($event, entry)">{{entry.station}}</a>
        </td>
        <td>{{ entry.platform }}</td>
        <td>{{ entry.source }}</td>
        <td>{{ entry.timeZone }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog *ngIf="job" [visible]="job !== undefined" [style]="{width:'750px'}" [showHeader]="false">
  <header>
    <h5>Request to Switch Source</h5>
  </header>
  <section>
    <div class="d-flex" style="column-gap: 10px">
      <p-dropdown [options]="options.groupedSources" [(ngModel)]="job.source" [group]="true" [style]="{width:'250px'}"
                  optionLabel="name" [required]="true" optionGroupLabel="type" optionGroupChildren="items"
                  placeholder="Select New Source" appendTo="body" scrollHeight="350px"></p-dropdown>
      <button pButton label="Select" class="p-button-sm p-button-outlined" (click)="selectSource()" [disabled]="!job.source"></button>
    </div>

    <p-table [value]="job.entries"
             [scrollable]="true"
             scrollHeight="500px">
      <ng-template pTemplate="header">
        <tr>
          <th>Station</th>
          <th>Platform</th>
          <th>Current Source</th>
          <th>New Source</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-entry>
        <tr>
          <td>{{ entry.station }}</td>
          <td>{{ entry.platform }}</td>
          <td>{{ entry.source }}</td>
          <td>
            <p-dropdown [options]="options.flatSources" [(ngModel)]="entry.nextSource" [style]="{width:'200px'}"
                        optionLabel="name" [required]="true"
                        placeholder="-" appendTo="body" scrollHeight="350px"></p-dropdown>
          </td>
          <td>
            <button pButton class="p-button-sm p-button-outlined" label="Remove" (click)="removeFromJob(entry)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
  <footer>
    <button pButton label="Apply" class="p-button-sm p-button-outlined" (click)="apply()"></button>
    <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="closeDialog()"></button>
  </footer>
</p-dialog>

<!--Confirm dialog-->
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
