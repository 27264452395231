<div class="page-header">
  <h3 class="title">Stations</h3>
  <div class="button-group" *ngIf="settings.canEdit">
    <button pButton label="Add Station" class="p-button-sm" [routerLink]="['/stations', 0]"></button>
  </div>
</div>
<div class="page-body">
  <p-table [value]="stations" [scrollable]="true" scrollHeight="flex" sortField="active" [sortOrder]="-1">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="callSign">Call Sign <p-sortIcon field="callSign"></p-sortIcon></th>
        <th pSortableColumn="active" sortOrder="-1">Active <p-sortIcon field="active"></p-sortIcon></th>
        <th pSortableColumn="mapping.callSign">Mapping <p-sortIcon field="mapping.callSign"></p-sortIcon></th>
        <th pSortableColumn="mapping.city">City <p-sortIcon field="mapping.city"></p-sortIcon></th>
        <th pSortableColumn="owner">Owner <p-sortIcon field="owner"></p-sortIcon></th>
        <th pSortableColumn="operator">Operator <p-sortIcon field="operator"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-station>
      <tr *ngIf="settings.showHidden || !station.hidden">
        <td><a [routerLink]="['/stations', station.stationId]">{{station.callSign}}</a></td>
        <td>
          <i class="pi pi-check" *ngIf="station.active"></i>
          <i class="pi pi-times text-danger" *ngIf="!station.active"></i>
        </td>
        <td>{{station.mapping?.callSign}}</td>
        <td>{{station.mapping?.city}}</td>
        <td>{{station.owner}}</td>
        <td>{{station.operator}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
