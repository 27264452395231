<div class="page-header">
  <h3 class="title">Cache Manager</h3>
</div>
<div class="page-body">
  <p-tabView>
    <p-tabPanel *ngFor="let section of allSections" [header]="section.header" [selected]="openedSection === section">
      <p-table #table
               [value]="section.entries"
               [columns]="section.columns"
               [loading]="section.loading"
               [scrollable]="true"
               scrollHeight="calc(100vh - 310px)">
        <ng-template pTemplate="caption">
          <div class="flex-between">
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Filter rows" style="width: 500px" />
            </span>
            <ng-container *ngIf="section.onTriggerRefresh">
              <button pButton label="Refresh Cache" class="p-button-sm p-button-outlined" (click)="section.onTriggerRefresh()"></button>
            </ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entry let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{ entry[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>
