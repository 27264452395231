import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request));
  }

  private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
    
    const allowedOrigins = [`${environment.apiUrl}`];
    if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
      //const authToken = this._oktaAuth.getAccessToken();
      const authToken = this._oktaAuth.getIdToken();
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: authToken ? ('Bearer ' + authToken) : ''
        }
      });
    }

    return request;
  }
}



