<div class="page-header">
  <h3 class="title">Schedule Overrides</h3>
  <div class="button-group" *ngIf="ui.canEditGroups">
    <button pButton label="Add Group" class="p-button-sm p-button-outlined" (click)="addGroup()"></button>
  </div>
</div>
<div class="page-body overflow-auto">
  <div *ngFor="let group of groups" class="group">
    <div class="main">
      <h5 class="title" (click)="expand(group)">
        <i class="pi pi-check" [ngClass]="group.expand ? 'pi-minus' : 'pi-plus'"></i>{{group.name}}
      </h5>
      <div class="count">{{group.stations.length}} stations</div>
      <div class="buttons">
        <button pButton class="p-button-sm p-button-outlined" label="Set Override" (click)="setOverride(group)" *ngIf="ui.canOverride"></button>
        <button pButton class="p-button-sm p-button-outlined" label="Modify" (click)="modify(group)" *ngIf="ui.canEditGroups"></button>
      </div>
    </div>
    <div class="station-list" *ngIf="group.expand">
      <div *ngFor="let station of group.stations">
        {{station.name}}
      </div>
    </div>
  </div>
</div>

<p-dialog *ngIf="group" [visible]="group !== undefined" [style]="{width:'650px'}" [showHeader]="false">
  <div class="group-dialog">
    <header>
      <ng-container *ngIf="ui.setOverrideMode">
        <h4>Set Override for {{group.name}}</h4>
      </ng-container>
      <ng-container *ngIf="!ui.setOverrideMode">
        <h4>Override Group</h4>
        <input type="text" pInputText [(ngModel)]="group.name" maxlength="100" [style]="{width:'100%'}"
               placeholder="Enter a name for this group" />
      </ng-container>
    </header>
    <div class="station-header">
      <h5>Stations</h5>
      <button pButton label="Add Station" class="p-button-sm p-button-outlined"
              (click)="stationPicker.show($event);"></button>
    </div>
    <div class="grid" *ngIf="group">
      <div class="grid-row">
        <div class="partner" style="width: 100px;" (click)="toggleAll()">All / None</div>
        <div class="partner" *ngFor="let partner of partners" (click)="toggleAll(partner.id)">
          {{partner.abbreviation}}
        </div>
      </div>
      <div *ngFor="let station of group.stations.selected" class="grid-row">
        <div class="station">
          <i class="pi pi-times" (click)="removeStation(station)"></i>
          <span>{{station.name}}</span>
        </div>
        <div class="partner" *ngFor="let partner of station.partners"
             [ngClass]="{selected: partner.selected, inactive: partner.inactive}" (click)="togglePartner(partner)">
          {{partner.abbreviation}}
        </div>
      </div>
    </div>
    <footer *ngIf="ui.setOverrideMode">
      <div>
        <button pButton *ngFor="let mode of overrideModes" label="{{mode.label}}" class="p-button-sm p-button-outlined"
                [ngClass]="mode.styleClass"
                (click)="force(mode)"></button>
      </div>
      <div>
        <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="closeDialog()"></button>
      </div>
    </footer>
    <footer *ngIf="!ui.setOverrideMode">
      <div>
        <button pButton label="Delete" class="p-button-sm p-button-outlined p-button-danger"
                (click)="deleteGroup()" *ngIf="group.id > 0"></button>
      </div>
      <div>
        <button pButton label="Save" class="p-button-sm p-button-outlined" (click)="saveGroup()"></button>
        <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="closeDialog()"></button>
      </div>
    </footer>
  </div>
</p-dialog>


<!--Confirm dialog-->
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>

<!--Station Picker--><p-overlayPanel #stationPicker [dismissable]="true" [showCloseIcon]="true">
  <ng-template pTemplate>
    <app-station-picker [stations]="group.stations.all" (onSelect)="selectStation($event)"></app-station-picker>
  </ng-template>
</p-overlayPanel>
