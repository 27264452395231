import { Component, Inject, OnInit, Input } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

  @Input() user: any;
  env: string = environment.env;
  version: string = environment.version;

  ngOnInit() {
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }
}
