<ng-container *ngIf="model; else loading">
  <section class="airing-info">
    <div class="row">
      <div class="col-md-3">
        <label>Station</label>
        <input type="text" pInputText [(ngModel)]="model.airing.callSign" readonly />
      </div>
      <div class="col-md-6">
        <label>Title</label>
        <input type="text" pInputText [(ngModel)]="model.title" readonly />
      </div>
      <div class="col-md-3">
        <label>TMS Id</label>
        <input type="text" pInputText [(ngModel)]="model.airing.tmsId" readonly />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label>Air Date</label>
        <input type="text" pInputText [(ngModel)]="model.startDate" readonly />
      </div>
      <div class="col-md-3">
        <label>Start Time</label>
        <input type="text" pInputText [(ngModel)]="model.startTime" readonly />
      </div>
      <div class="col-md-3">
        <label>End Time</label>
        <input type="text" pInputText [(ngModel)]="model.endTime" readonly />
      </div>
      <div class="col-md-3">
        <label>Duration</label>
        <input type="text" pInputText [(ngModel)]="model.duration" readonly />
      </div>
    </div>
  </section>
  <section *ngIf="ui.canEdit">
    <p-message *ngIf="!model.partners.length" severity="warn" text="No partners are configured for this station"></p-message>
    <p-table *ngIf="model.partners.length" [value]="model.partners">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 200px">Partners</th>
          <th>Blackouts</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td style="width: 200px">{{row.name}}</td>
          <td>
            <span class="blackouts">
              <span *ngFor="let blackout of row.blackouts" class="blackout">
                <span class="time">
                  {{blackout.eventStart|date:'shortTime'}} - {{blackout.eventEnd|date:'shortTime'}}
                </span>
                <span class="delete">
                  <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"
                          (click)="deleteBlackout(blackout)"
                          *ngIf="blackout.allowDelete"
                          pTooltip="Remove Blackout" [showDelay]="500"></button>
                </span>
                <span class="info">
                  Updated {{blackout.lastUpdated|date:'short'}} by
                  <span *ngIf="blackout.createdBySignalEventRuleId">
                    <a href="" target="_blank" [routerLink]="['/blackout-schedule']" [queryParams]="{rule: blackout.createdBySignalEventRuleId}">Rule #{{blackout.createdBySignalEventRuleId}}</a>
                  </span>
                  <span *ngIf="!blackout.createdBySignalEventRuleId">
                    {{blackout.updatedBy}}
                  </span>
                </span>
              </span>
              <span *ngIf="row.allowCreate">
                <button pButton class="p-button-sm p-button-outlined blackout-btn" label="Insert Blackout"
                        (click)="createBlackout(row)"></button>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td>&nbsp;</td>
          <td>
            <button pButton class="p-button-sm p-button-outlined blackout-btn" label="Blackout All"
                    (click)="blackoutAll()"
                    *ngIf="ui.buttons.blackoutAll"></button>
            <button pButton class="p-button-sm p-button-danger blackout-btn" label="Remove All"
                    (click)="deleteAll()"
                    *ngIf="ui.buttons.deleteAll"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
</ng-container>

<ng-template #loading>
  <div class="modal-loading">
    <i class="pi pi-spin pi-spinner"></i>
  </div>
</ng-template>
