export interface AppUser {
  authorized: boolean;
  appUserId: number;
  firstName: string;
  lastName: string;
  oktaLogin: string;
  permissions: AppPermission[];
  stationPermissions: any;
}

export enum AppPermission {
  // ADN
  vwSourceCon = 10,
  vwADNMap = 11,
  mngADNMap = 12,
  mngSourceCon = 13,

  // DMA
  vwDMA = 20,
  mngDMA = 21,

  // System
  vwSysHealth = 30,
  vwSysSettings = 31,
  mngSysSettings = 32,
  vwActivityLog = 33,
  // Users
  vwUser = 40,
  mngUser = 41,

  // Overrides
  vwOverrideGrp = 50,
  mngOverrideGrp = 51,
  mngOverride = 52,

  // Station Admin
  vwStations = 60,
  mngStations = 61,

  // Station Permissions
  vwStnSubRules = 100,
  mngStnSubRules = 101,
  vwStn = 110,
  vwStnSchedule = 111,
  mngStnSub = 112,
}
