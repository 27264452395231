import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { QueueJobActionData, QueueJobService } from '../../_shared/components/queue-job/queue-job.service';
import { AppPermission } from '../../_shared/models/user';
import { ApiService } from '../../_shared/services/api.service';
import { AuthService } from '../../_shared/services/auth.service';
import { UiService } from '../../_shared/services/ui.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-station-overview',
  templateUrl: './station-overview.component.html',
  styleUrls: ['./station-overview.component.scss']
})
export class StationOverviewComponent implements OnInit {

  constructor(
    private uiService: UiService,
    private apiService: ApiService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private queueJobService: QueueJobService,
    private route: ActivatedRoute) { }

  @ViewChild('table') table: Table;
  entries: any[];
  filters: any = { stations: [], platforms: [] };
  options: any = { flatSources: [], groupedSources: [] };
  canEdit = false;
  selection = { any: false, all: false, count: 0 };
  job;
  filteredEntries: any;

  ngOnInit() {
    this.canEdit = this.authService.isPermitted(AppPermission.mngSourceCon);

    // Automatically select station
    var stationId = parseInt(this.route.snapshot.queryParams['station']) || undefined;
    this.refresh(stationId);
  }

  refresh(stationIdToOpen?: number) {
    this.apiService.get({
      method: "station/overview",
      blockUi: true,
      onSuccess: (r) => {
        this.entries = r.result.platforms;

        var extractOptions = (idField, nameField) => {
          return Array.from(new Set(this.entries.map(x => x[idField])))
            .map(id => ({ id, name: this.entries.find(x => x[idField] === id)[nameField] }))
            .sort((a, b) => a.name.localeCompare(b.name));
        }

        this.filters.stations = extractOptions("stationId", "station");
        this.filters.platforms = extractOptions("platformId", "platform");
        this.filters.sources = extractOptions("sourceId", "source");

        this.filters.timeZones = [];
        this.entries.forEach(x => {
          x.timeZone = x.timeZone.replace(" Observing", "");
          if (!this.filters.timeZones.includes(x.timeZone))
            this.filters.timeZones.push(x.timeZone);
        });

        this.options.flatSources = r.result.sources.map(x => x.items).flat();
        this.options.groupedSources = r.result.sources;

        this.apiService.get({
          method: "lookup/adn/mappingProfiles",
          onSuccess: (r) => {
            var items = r.result.map(x => ({
              sourceId: null,
              isProfile: true,
              adnMappingId: x.id,
              name: `${x.name} mapping`,
            }));

            this.options.groupedSources.push({ type: "ADN Mapping Profile", items });
          }
        });

        // Automatically select station
        if (stationIdToOpen > 0) {
          this.table.filter([stationIdToOpen], 'stationId', 'in');
          setTimeout(() => {
            this.selection.all = true;
            this.selectAllNone();
            this.newJob();
          });
        }
      }
    });
  }

  newJob() {
    this.job = {
      entries: this.entries.filter(x => x.selected)
    };    
  }

  removeFromJob(entry) {
    entry.selected = false;
    this.job.entries = this.entries.filter(x => x.selected);
  }

  selectSource() {
    this.job.entries.forEach(x => {
      if (this.job.source.isProfile) {
        var sourceId = x.adnMappings.find(m => m.adnMappingId === this.job.source.adnMappingId)?.adnId;
        x.nextSource = this.options.flatSources.find(s => s.sourceId === sourceId);
      } else {
        x.nextSource = this.job.source;
      }
    });
  }

  selectionChanged() {
    this.selection.count = (this.entries.filter(x => x.selected) || []).length;
    this.selection.any = this.selection.count > 0;
    this.selection.all = this.selection.count === this.entries.length;
  }

  selectAllNone() {    
    var entries = this.filteredEntries?.length ? this.filteredEntries : this.entries;

    entries.forEach(x => { x.selected = this.selection.all; });
    this.selectionChanged();
  }

  openStation($event: Event, entry) {
    this.uiService.open.station(entry.stationId);

    $event.preventDefault();
  }

  apply() {
    if (this.job.entries.some(x => !x.nextSource)) {
      this.uiService.toast.error("A new source must be selected for all rows");
      return;
    }

    var messageEnd = this.job.entries.length === 1
      ? `${this.job.entries[0].station} - ${this.job.entries[0].platform}`
      : `${this.job.entries.length} station platforms`

    this.confirmationService.confirm({
      header: `Source Change`,
      message: `Are you sure that you want to switch the source for ${messageEnd}`,
      acceptLabel: `Yes`,
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "p-button-danger",
      accept: () => {
        var actions: QueueJobActionData[] = this.job.entries.map(x => ({
          stationId: x.stationId,
          platformId: x.platformId,
          sourceId: x.nextSource.sourceId,
          description: `${x.station} - ${x.platform} switching to ${x.nextSource.name}`
        }));

        this.sendToQueue(actions);
      }
    });
  }

  sendToQueue(actions: QueueJobActionData[]) {
    var queue = this.queueJobService.launchForSourceSwitch(actions);
    this.closeDialog();

    queue.onClose.subscribe(r => {
      if (r?.refresh === true)
        this.refresh();
    });
  }

  onFilter(event) {
    this.filteredEntries = event.filteredValue;
  }

  closeDialog() {
    this.job = undefined;
    this.entries.forEach(x => { x.nextSource = undefined; });
  }
}
