<p-menubar [model]="navMenuItems">
  <ng-template pTemplate="start">
    <a [routerLink]="['']" class="brand">
      <img src="assets/logo.png" alt="Paramount">
      <span class="app-title">Signal Manager<small class="env">{{env}}</small></span>
    </a>
  </ng-template>
  <ng-template pTemplate="end">
    <ul *ngIf="authorized">
      <li>
        <app-user-menu [user]="user"></app-user-menu>
      </li>
    </ul>
  </ng-template>
</p-menubar>
<div class="unauthorized" *ngIf="authFailedMessage">
  {{authFailedMessage}}
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

<p-toast key="appToast" position="top-right"></p-toast>

<p-blockUI [blocked]="uiService.config.showSpinner">
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI>

