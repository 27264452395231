import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ZIXI_LOCATIONS } from '../../_shared/models/constants';
import { ApiService } from '../../_shared/services/api.service';

@Component({
  selector: 'app-station-summary',
  templateUrl: './station-summary.component.html',
  styleUrls: ['./station-summary.component.scss']
})
export class StationSummaryComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  station: any;
  platforms: any[];
  autoRefresh = true;
  locations = ZIXI_LOCATIONS;

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.apiService.get({
      method: "station/summary",
      params: { stationId: this.config.data.stationId },
      onSuccess: (r) => {
        this.station = r.result.station;
        this.platforms = r.result.platforms

        this.config.header = this.station.callSign;
      }
    });
  }
}
