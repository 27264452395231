import { Component, ElementRef, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { ApiService } from '../../_shared/services/api.service';
import { UiService } from '../../_shared/services/ui.service';
import { Airing, Station } from '../../_shared/models/models';
import { Subscription, timer } from 'rxjs';
import { OverrideType } from 'src/app/_shared/models/enums';
import { QueueJobService, QueueJobActionData } from '../../_shared/components/queue-job/queue-job.service';
import { AuthService } from '../../_shared/services/auth.service';
import { AppPermission } from '../../_shared/models/user';
import { Router } from '@angular/router';


@Component({
  selector: 'app-airing-schedule',
  templateUrl: './airing-schedule.component.html',
  styleUrls: ['./airing-schedule.component.scss']
})
export class AiringScheduleComponent {
  results: ScheduleResults;
  settings: ScheduleSettings;
  filters: any = {};
  options: any = {};
  contextMenuItems: MenuItem[] = [];
  @ViewChild('scrollable') public scrollable: ElementRef;
  @ViewChild('contextMenu', { static: false }) contextMenu: ContextMenu;
  timer: Subscription;
  overrideModes: OverrideMode[] = [
    { type: null, label: "Run Schedule", styleClass: "p-button-primary" },
    { type: OverrideType.ForceBlackout, label: "Force Blackout", styleClass: "p-button-danger" },
    { type: OverrideType.ForceStream, label: "Force Streaming", styleClass: "p-button-success" },
  ];

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authService: AuthService,
    private queueJobService: QueueJobService,
    private confirmationService: ConfirmationService,
    private router: Router) { }

  ngOnInit() {
    this.settings = {
      pixelsPerMinute: 5,
      gap: 4,
      block: false,
      canOverride: this.authService.isPermitted(AppPermission.mngOverride)
    };

    this.initTimeline();
    this.initSchedule();
  }

  ngOnDestroy() {
    if (this.timer)
      this.timer.unsubscribe();
  }

  initTimeline() {
    var defaultZone = DateTime.now().toFormat('z');

    this.options.timelines = [{ id: Timeline.UserLocal, name: defaultZone }];

    if (defaultZone !== "America/New_York")
      this.options.timelines.push({ id: Timeline.Eastern, name: "America/New_York" });

    this.options.timelines.push({ id: Timeline.StationLocal, name: "Stations' Local Time" });

    this.settings.timeline = Timeline.UserLocal;

    this.results = { blocks: [], stations: [] };
    var dt = DateTime.now().startOf('day');

    // Set up time blocks (15 min intervals)
    var day = dt.day;
    while (dt.day === day) {
      this.results.blocks.push({
        hour: dt.hour,
        min: dt.minute,
        text: dt.toLocaleString(DateTime.TIME_SIMPLE)
      });

      dt = dt.plus({ minutes: 15 });
    }
  }

  initSchedule() {
    this.results.stations = [];

    this.apiService.get({
      method: "schedule/init",
      blockUi: true,
      onSuccess: (r) => {
        this.settings.minDate = DateTime.fromISO(r.result.scheduleStart).toJSDate();
        this.settings.maxDate = DateTime.fromISO(r.result.scheduleEnd).toJSDate();

        this.results.stations = r.result.stations;

        this.results.stations.forEach(s => {
          s.partners.forEach(p => {
            p.class ??= {};

            if (p.override)
              p.class[`ov${p.override}`] = true;
          });
        });

        this.options.stations = this.results.stations.map(s => ({ id: s.id, name: s.callSign }));

        var dt = DateTime.now().startOf('day');

        this.setDate(dt);

        if (scroll && this.settings.isToday)
          this.scrollToCurrentTime();
      }
    });
  }

  getAirings() {
    this.settings.block = true;

    var params = {
      day: this.settings.luxonDate.toISODate(),
      ianaTz: this.settings.zone
    };

    this.results.stations.forEach(station => { station.airings = [] });

    this.apiService.get({
      method: "schedule/airings", params, onSuccess: (r) => {
        this.settings.block = false;

        this.results.stations.forEach(station => {
          (r.result[station.id] || []).forEach(obj => {
            var airing: Airing = {
              id: obj[0],
              title: obj[1],
              start: DateTime.fromISO(obj[2]),
              end: DateTime.fromISO(obj[3]),
              style: {},
              class: {},
              partnerClass: {}
            };

            var pos = this.getEventPosition(airing.start, airing.end, station);

            // If airing does not fit on schedule then no reason to render (1440 mins/day)
            if (pos.left + pos.width <= 0 || pos.left >= (1440 * this.settings.pixelsPerMinute)) return;

            // Airings that start before midnight are trimmed so the title can be read
            if (pos.left < 0) {
              pos.width = pos.width + pos.left;
              pos.left = 0;
            }

            airing.style.left = `${pos.left}px`;
            airing.style.width = `${pos.width - this.settings.gap}px`;

            // Add css class object to store black / live
            station.partners.forEach(p => { airing.partnerClass[p.id] = {}; });

            station.airings.push(airing);
          });
        });

        // Filters
        var airings = this.results.stations
          .map(s => s.airings.map(a => a.title))
          .flat();

        this.options.airings = [...new Set(airings)]
          .sort((a, b) => a.localeCompare(b))
          .map(x => ({ title: x }));

        this.getSignalEvents();

        this.settings.showLive = this.settings.isToday && this.settings.timeline !== Timeline.StationLocal;

        if (this.settings.showLive) {
          this.refreshLive();
          var delay = (60 - DateTime.now().second) * 1000; // initial delay so refresh occurs at 1 second of each minute
          this.timer = timer(delay, 60000).subscribe(() => { this.refreshLive(); });
        }
      }
    });
  }

  refreshLive() {
    var now = DateTime.now();
    var startOfDay = this.settings.luxonDate;

    if (this.settings.timeline === Timeline.Eastern)
      startOfDay = startOfDay.setZone("America/New_York", { keepLocalTime: true });

    // Advance time indicator bar
    var minuteOfDay = now.diff(startOfDay, 'minutes').minutes;
    this.settings.liveBarPos = minuteOfDay * this.settings.pixelsPerMinute;

    // Add live (green) styling to airings/blackouts
    this.results.stations.forEach(s => {
      s.airings.forEach(a => {
        a.class.live = a.start <= now && a.end >= now;
        for (let key in a.partnerClass) {
          a.partnerClass[key].live = a.class.live;
        }
      });

      s.partners.forEach(p => {
        p.blackouts?.forEach(b => {
          b.class.live = b.start <= now && b.end >= now;
        });
      });
    });
  }

  getSignalEvents() {
    var params = { day: this.settings.luxonDate.toISODate() }

    this.apiService.get({
      method: "signalEvent/day",
      params,
      onSuccess: (r) => {
        // Clear events
        this.results.stations.forEach(s => {
          s.airings.forEach(a => {
            a.class.black = false;

            for (let key in a.partnerClass) {
              a.partnerClass[key].black = false;
            }
          });

          s.partners.forEach(p => { p.blackouts = []; });
        });

        // Iterate through blackouts
        r.result.forEach((event: any) => {
          var station = this.results.stations.find(s => s.id === event.stationId);
          if (station) {
            var partner = station.partners.find(p => p.id === event.partnerId);
            if (partner) {
              var blackout: any = {
                id: event.id,
                start: DateTime.fromISO(event.start),
                end: DateTime.fromISO(event.end),
                class: {}
              };

              var pos = this.getEventPosition(blackout.start, blackout.end, station);
              blackout.style = { left: `${pos.left}px`, width: `${pos.width - this.settings.gap}px` }

              partner.blackouts.push(blackout);

              station.airings
                .filter((a: Airing) => { return a.start < blackout.end && blackout.start < a.end; })
                .forEach((a: Airing) => {
                  a.class.black = true;
                  a.partnerClass[event.partnerId].black = true;
                });
            }
          }
        });

        this.refreshLive();
      }
    });
  }

  getEventPosition(start: DateTime, end: DateTime, station: Station) {
    var startOfDay = this.settings.luxonDate;

    if (this.settings.timeline !== Timeline.UserLocal) {
      var zone = this.settings.timeline === Timeline.Eastern ? "America/New_York" : station.zone;

      startOfDay = startOfDay.setZone(zone, { keepLocalTime: true });
    }

    var minsIntoDay = start.diff(startOfDay, 'minutes').minutes;
    var duration = end.diff(start, "minutes").toObject().minutes || 0;

    return {
      left: minsIntoDay * this.settings.pixelsPerMinute,
      width: (duration < 1 ? 1 : duration) * this.settings.pixelsPerMinute
    };
  }

  toggleExpand(station: Station) {
    if (!this.canEditBlackout(station.id))
      return;

    if (station.partners.length === 0) {
      this.uiService.toast.error(`No partners are configured for ${station.callSign}`);
      return;
    }

    station.expand = !station.expand;
  }

  setDate(date: DateTime) {
    this.settings.jsDate = date.toJSDate();
    this.dateChanged();
  }

  dateChanged() {
    this.settings.luxonDate = DateTime.fromJSDate(this.settings.jsDate).startOf('day');
    this.settings.isToday = DateTime.now().startOf("day").equals(this.settings.luxonDate.startOf("day"));
    this.settings.prevDay = this.settings.luxonDate.plus({ days: -1 }).toJSDate();
    this.settings.nextDay = this.settings.luxonDate.plus({ days: 1 }).toJSDate();

    switch (this.settings.timeline) {
      case Timeline.Eastern:
        this.settings.zone = "America/New_York";
        break;
      case Timeline.UserLocal:
        this.settings.zone = this.settings.luxonDate.zoneName;
        break;
      default:
        this.settings.zone = "";
    }

    this.getAirings();
  }

  skipDays(days: number) {
    this.setDate(this.settings.luxonDate.plus({ days }));
  }

  scrollToCurrentTime() {
    var now = DateTime.now();
    this.scrollable.nativeElement.scrollLeft = 60 * now.hour * this.settings.pixelsPerMinute;
  }

  searchChanged() {
    var text = (this.filters.search || "").toLowerCase();

    this.settings.searchEnabled = text.length > 0;

    this.results.stations.forEach(s => {
      s.airings.forEach(a => {
        a.class.highlight = this.settings.searchEnabled && a.title?.toLowerCase().includes(text);
      });
    });
  }

  menu = {
    airing: ($event: MouseEvent, station: Station, airing: Airing, partner?) => {
      var now = DateTime.now();

      var items: MenuItem[] = [
        {
          label: "View Airing",
          command: () => this.openAiring(airing)
        }
      ];

      var now = DateTime.now();

      if (now <= airing.end) {
        items.push({
          label: "Insert Blackout",
          command: () => this.createBlackout(station, airing, partner),
          visible: this.canEditBlackout(station.id),
          disabled: partner
            ? (airing.start <= now && partner.blackouts.some(b => b.start <= now && b.end >= now))
            : (airing.class.black === true)
        });
      }

      this.openContextMenu($event, items);
    },
    blackout: ($event: MouseEvent, station: Station, blackout) => {
      var items = [
        {
          label: "Remove Blackout",
          visible: this.canEditBlackout(station.id) && DateTime.now() < blackout.end,
          command: () => this.deleteBlackout(blackout.id)
        },
      ];

      this.openContextMenu($event, items);
    },
    station: ($event: MouseEvent, station: Station) => {
      var items = [
        {
          label: "View Station",
          command: () => this.openStation(station)
        },
        {
          label: "Switch Source",
          command: () => {
            this.router.navigate(['station-overview'], { queryParams: { station: station.id } });
          }
        },
        {
          label: "Override All",
          visible: this.settings.canOverride,
          items: this.overrideModes.map(m => ({
            label: m.label,
            command: () => this.forceAll(station, m)
          }))
        },
      ];

      this.openContextMenu($event, items);
    },
    partner: ($event: MouseEvent, station: Station, partner) => {
      var items = this.overrideModes.map(m => ({
        label: m.label,
        visible: this.settings.canOverride,
        command: () => this.applyForce(m, station, [partner]),
        disabled: partner.override === m.type
      }));

      this.openContextMenu($event, items);
    }
  };

  canEditBlackout(stationId: number) {
    return this.authService.isPermittedForStation(AppPermission.mngStnSub, stationId);
  }

  forceAll(station: Station, mode: OverrideMode) {
    this.applyForce(mode, station, station.partners);
  }

  openAiring(airing) {
    var dialog = this.uiService.open.airing(airing.id);

    dialog.onClose.subscribe(r => {
      if (r?.refresh === true) {
        this.getSignalEvents();
      }
    });
  }

  openStation(station) {
    this.uiService.open.station(station.id);
  }

  openContextMenu(event: MouseEvent, menuItems: MenuItem[]): void {
    if (!menuItems.some(x => x.visible !== false)) {
      menuItems = [{ label: "No actions available", disabled: true }];
    }

    this.contextMenuItems = menuItems;
    this.contextMenu.show(event);

    event.stopPropagation();
  }

  createBlackout(station: Station, airing: Airing, partner?) {
    var data = {
      stationId: station.id,
      partnerIds: partner ? [partner.id] : station.partners.map(x => x.id),
      startDate: airing.start.toISO(),
      endDate: airing.end.toISO(),
      startEventDescription: `Start Blackout [${airing.title}]`,
      endEventDescription: `End Blackout [${airing.title}]`
    };

    this.settings.block = true;

    this.apiService.post({
      method: "signalEvent/create",
      data,
      onSuccess: (r) => {
        this.uiService.toast.success("Blackout scheduled successfully");
        this.getSignalEvents();
        this.settings.block = false;
      }
    });
  }

  deleteBlackout(groupId: number) {
    this.confirmationService.confirm({
      header: `Delete Blackout`,
      message: `Are you sure that you want to remove this blackout?`,
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "p-button-danger",
      accept: () => {
        this.apiService.delete({
          method: `signalEvent`,
          params: { groupIds: [groupId] },
          onSuccess: (r) => {
            this.uiService.toast.success("Blackout removed successfully");
            this.getSignalEvents();
          }
        });
      }
    });
  }

  applyForce(mode: OverrideMode, station: Station, partners: any[]) {
    this.confirmationService.confirm({
      header: `Set Override on ${station.callSign}`,
      message: `Are you sure that you want to ${mode.label} on ${station.callSign}?`,
      acceptLabel: `Yes, ${mode.label}`,
      rejectLabel: "Cancel",
      acceptButtonStyleClass: mode.styleClass,
      accept: () => {
        var actions: QueueJobActionData[] = partners.map(p => ({
          stationId: station.id,
          partnerId: p.id,
          description: `${station.callSign} - ${p.name}`
        }));

        var dialog = this.queueJobService.launchForOverride(actions, mode.type);

        dialog.onClose.subscribe(r => {
          if (r?.refresh === true && r.stationIds?.length === 1) {
            partners.forEach(p => {
              p.class = {
                ...p.class,
                ov1: mode.type === OverrideType.ForceBlackout,
                ov2: mode.type === OverrideType.ForceStream
              };
            });
          }
        });
      }
    });
  }
}

enum Timeline { UserLocal, StationLocal, Eastern }

export interface ScheduleSettings {
  pixelsPerMinute: number;
  gap: number;
  liveBarPos?: number;
  block: boolean;
  jsDate?: Date;
  luxonDate?: DateTime;
  prevDay?: Date;
  nextDay?: Date;
  minDate?: Date;
  maxDate?: Date;
  timeline?: Timeline;
  searchEnabled?: boolean;
  showLive?: boolean;
  isToday?: boolean;
  zone?: string;
  canOverride: boolean;
}

export interface ScheduleResults {
  blocks: any[];
  stations: Station[];
}

interface OverrideMode {
  type: OverrideType,
  label: string,
  styleClass: string
}
