import { Component, OnInit, } from '@angular/core';
import { AppPermission } from '../../../_shared/models/user';
import { ApiService } from '../../../_shared/services/api.service';
import { AuthService } from '../../../_shared/services/auth.service';
import { UiService } from '../../../_shared/services/ui.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private uiService: UiService) { }

  users: User[];
  selectedUser: User;
  canEdit = false;
  header: string;

  ngOnInit(): void {
    this.canEdit = this.authService.isPermitted(AppPermission.mngUser);

    this.refresh();
  }

  refresh() {
    this.apiService.get({
      method: "user",
      onSuccess: (r) => {
        this.users = r.result;
      }
    });
  }

  selectUser(event: any) {
    this.selectedUser = event.data as User;
    this.openDialog();
  }

  addNew() {
    this.selectedUser = <User>{ appUserId: 0 };
    this.openDialog();
  }

  openDialog() {
    var header = this.selectedUser.appUserId > 0
      ? `Edit User - ${this.selectedUser.firstName} ${this.selectedUser.lastName}`
      : "New User";

    var dialog = this.uiService.open.user(this.selectedUser.appUserId, header);

    dialog.onClose.subscribe(r => {
      if (r?.refresh === true) {
        this.refresh();
      }

      this.selectedUser = undefined;
    });
  }
}

interface User {
  appUserId: number,
  oktaLogin: string,
  firstName: string,
  lastName: string
}
