<div class="page-header">
  <h3 class="title">Blackout Schedule</h3>
</div>
<div class="page-body">
  <div class="rule" *ngIf="config.rule">
    <h6>Rule #{{config.rule.signalEventRuleId}}</h6>
    <div class="chips">
      <p-chip label="Station: {{config.rule.callSign}}"></p-chip>
      <p-chip label="Title: {{config.rule.titleToMatch}}"></p-chip>
      <p-chip label="Partners: {{config.rule.partnersCsv}}"></p-chip>
      <p-chip label="Dates: {{config.rule.dates}}"></p-chip>
      <p-chip label="Status: {{config.rule.active ? 'Active':'Inactive'}}"></p-chip>
    </div>
  </div>

  <p-table [value]="results" sortField="blackout.start" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="blackout.callSign">Station</th>
        <th pSortableColumn="blackout.start">Blackout Start</th>
        <th pSortableColumn="blackout.end">Blackout End</th>
        <th>Airing</th>
        <th *ngFor="let p of config.partners" class="text-center">{{p.name}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{row.blackout.callSign}}</td>
        <td>{{row.blackout.start|date:"short"}}</td>
        <td>{{row.blackout.end|date:"short"}}</td>
        <td>
          <a href="" (click)="openAiring($event, row.blackout.airingId)">{{row.blackout.title}}</a>
        </td>
        <td *ngFor="let p of config.partners" class="text-center">
          <button pButton *ngIf="row.groups[p.partnerId]" icon="pi pi-check" class="p-button-rounded" (click)="openAiring($event, row.blackout.airingId)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
