<div class="page-header">
  <h3 class="title">Activity Log</h3>
</div>
<div class="page-body">

  <p-table [value]="entries"
           [lazy]="true"
           (onLazyLoad)="loadResult($event)"
           [paginator]="true"
           [rows]="50"
           [totalRecords]="totalRecords"
           [loading]="loading"
           [scrollable]="true" scrollHeight="flex" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="activityType">Activity Type <p-sortIcon field="activityType"></p-sortIcon></th>
        <th pSortableColumn="performedOnUTC">Performed On <p-sortIcon field="performedOnUTC"></p-sortIcon></th>
        <th pSortableColumn="user">User <p-sortIcon field="user"></p-sortIcon></th>
        <th pSortableColumn="station">Station <p-sortIcon field="station"></p-sortIcon></th>
        <th pSortableColumn="platform">Platform <p-sortIcon field="platform"></p-sortIcon></th>
        <th pSortableColumn="audience">Audience <p-sortIcon field="audience"></p-sortIcon></th>
        <th>Status</th>
        <th>Details</th>
      </tr>
      <tr>
        <th>
          <p-columnFilter field="activityType" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="options.activityTypes" (onChange)="filter($event.value)" placeholder="Any" optionLabel="name" [showClear]="true"></p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="performedOnUTC" type="date" [showMenu]="false">
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar (onSelect)="filter($event)" (onClear)="filter($event)" placeholder="Any" [showClear]="true"></p-calendar>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="user" type="text" matchMode="equals" [showMenu]="false" placeholder="Any"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="station" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="options.stations" (onChange)="filter($event.value)" placeholder="Any" optionLabel="name" [showClear]="true"></p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="platform" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="options.platforms" (onChange)="filter($event.value)" placeholder="Any" optionLabel="name" [showClear]="true"></p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="audience" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="options.audiences" (onChange)="filter($event.value)" placeholder="Any" optionLabel="name" [showClear]="true"></p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="status" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="options.statuses" (onChange)="filter($event.value)" placeholder="Any" optionLabel="name" [showClear]="true"></p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>&nbsp;</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entry>
      <tr>
        <td>{{ entry.activityType }}</td>
        <td>{{ entry.performedOnUTC | date:"short" }}</td>
        <td>{{ entry.user }}</td>
        <td>{{ entry.station }}</td>
        <td>{{ entry.platform }}</td>
        <td>{{ entry.audience }}</td>
        <td>{{ entry.status }}</td>
        <td>
          <a href="" class="log-details" (click)="showDetails($event, entry)">
            <span *ngFor="let x of entry.activityLogDetail">
              {{x.name}} | {{x.value}}
            </span>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" [style]="{width: '800px'}">
  <div *ngIf="selected" class="selected-entry">
    <div *ngFor="let x of selected.activityLogDetail" class="detail">
      <h5>{{x.name}}</h5>
      <span>{{x.value}}</span>
    </div>
  </div>
</p-overlayPanel>
