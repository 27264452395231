import { Component, OnInit } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';
import { AppPermission, AppUser } from './_shared/models/user';
import { AuthService } from './_shared/services/auth.service';
import { UiService } from './_shared/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public uiService: UiService,
    public authService: AuthService,
    private _oktaStateService: OktaAuthStateService) { }

  navMenuItems: MenuItem[];
  userMenuItems: MenuItem[];
  env: string = environment.env;
  authorized = false;
  authFailedMessage: string;
  user: AppUser;

  ngOnInit() {
    this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
    ).subscribe((s: AuthState) => {
      if (s.isAuthenticated) {
        this.authService.isAuthorized$.subscribe(authorized => {
          if (authorized !== null) {
            this.authorized = authorized;

            if (this.authorized) {
              this.user = this.authService.user;

              this.getNavItems();
              this.authFailedMessage = undefined;
            }
            else {
              var oktaUserName = s.idToken?.claims.preferred_username;
              this.authFailedMessage = oktaUserName ? `Authorization failed for Okta user ${oktaUserName}` : "Authorization failed";
            }
          }
        });

        this.authService.init();
      }
    });
  }

  getNavItems() {
    this.navMenuItems = [
      {
        label: 'Airing Schedule',
        routerLink: ['schedule'],
        visible: this.authService.isPermitted(AppPermission.vwStnSchedule)
      },
      {
        label: 'Blackouts',
        items: [
          {
            label: 'Overrides',
            routerLink: ['schedule-overrides'],
            visible: this.authService.isPermitted(AppPermission.vwOverrideGrp)
          },
          {
            label: 'Rules',
            routerLink: ['blackout-rules'],
            visible: this.authService.isPermitted(AppPermission.vwStnSubRules),
          },
          {
            label: 'Schedule',
            routerLink: ['blackout-schedule'],
            visible: this.authService.isPermitted(AppPermission.vwStnSubRules),
          }
        ]
      },
      {
        label: 'Station Overview',
        routerLink: ['station-overview']
      },
      {
        label: 'Administration',
        items: [
          {
            label: 'Stations',
            routerLink: ['stations'],
            visible: this.authService.isPermitted(AppPermission.vwStations)
          },
          {
            label: 'Users',
            routerLink: ['users'],
            visible: this.authService.isPermitted(AppPermission.vwUser)
          },
          {
            label: 'ADN Mappings',
            routerLink: ['adn-mapper'],
            visible: this.authService.isPermitted(AppPermission.vwADNMap)
          },
          {
            label: 'Activity Log',
            routerLink: ['activity-log'],
            visible: this.authService.isPermitted(AppPermission.vwSysSettings)
          },
          {
            label: 'Cache Manager',
            routerLink: ['cache-manager'],
            visible: this.authService.isPermitted(AppPermission.vwSysSettings)
          },
        ]
      }
    ];

    // Remove any top level menuItems that contain no [visible] children
    this.navMenuItems = this.navMenuItems.filter(x => x.routerLink || x.items.some(y => y.visible !== false));
  }
}
