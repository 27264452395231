import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-station-picker',
  templateUrl: './station-picker.component.html',
  styleUrls: ['./station-picker.component.scss']
})
export class StationPickerComponent implements OnInit {

  constructor() { }

  @Input() stations: StationPickerItem[];
  @Input() scrollHeight: string;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  matches: StationPickerItem[];
  singleMatch: StationPickerItem;
  searchText: string;

  ngOnInit() {
    this.scrollHeight = this.scrollHeight || "400px";

    this.findMatches();
  }

  findMatches() {
    var text = (this.searchText || "").toLowerCase();

    this.matches = this.stations.filter(s => {
      return !s.selected && s.name?.toLowerCase().includes(text);
    });

    this.singleMatch = this.matches.length === 1 ? this.matches[0] : null;
  }

  selectStation(station) {
    station.selected = true;
    this.onSelect.emit({ station });
    this.findMatches();
  }

  selectFirst() {
    if (this.singleMatch) {
      this.selectStation(this.singleMatch);
      this.searchText = "";
      this.findMatches();
    }
  }

  clearText() {
    this.searchText = null;
    this.findMatches();
  }
}

interface StationPickerItem {
  id: number;
  name: string;
  selected: boolean;
}
