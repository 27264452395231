import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { filter, map } from "rxjs";
import { AppPermission } from "../models/user";
import { AuthService } from "../services/auth.service";

export const HttpsGuard = () => {
  if (window.location.hostname === "localhost") return true;

  const currentProtocol = window.location.protocol.replace(':', '');
  if (currentProtocol !== 'https') {
    const url = window.location.href.replace('http', 'https');
    window.location.href = url;
    return false;
  }
  return true;
}

export const PermissionsGuard = (permission: AppPermission) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthorized$.pipe(
    filter(x => !!x),
    map(authorized => {
      if (authorized && authService.isPermitted(permission))
        return true;

      return router.navigate(['unauthorized']);
    })
  );
}
