<div class="page-header">
  <h3 class="title">Default ADN Mappings</h3>
  <p-dropdown [options]="options.profiles" [(ngModel)]="profileId" (onChange)="refresh()" (click)="profileClicked()" [disabled]="dirty" appendTo="body"
              optionLabel="name" optionValue="id"></p-dropdown>
  <div style="display: flex; column-gap: 10px" *ngIf="canEdit">
    <button pButton label="Import" class="p-button-sm p-button-outlined" (click)="openImport()"></button>
    <button pButton label="Clear All" class="p-button-sm p-button-outlined" (click)="clearAll()"></button>
  </div>

  <div class="button-group" *ngIf="adns && canEdit">
    <button pButton label="Save" class="p-button-sm p-button-outlined" (click)="save()"></button>
    <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="cancel()" [disabled]="!dirty"></button>
  </div>
</div>
<div class="page-body">
  <div class="adn-wrapper">
    <div *ngFor="let adn of adns" class="adn">
      <h5 class="title">
        <span>{{adn.name}}</span>
        <i class="pi pi-list" (click)="openMenu($event, adn)"></i>
      </h5>
      <div class="stations" pDroppable="dd" (onDrop)="drop(adn)">
        <div *ngFor="let station of adn.stations" pDraggable="dd" (onDragStart)="dragStart(station)" (onDragEnd)="dragEnd()">
          {{station.callSign}}
        </div>
      </div>
    </div>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>

<p-dialog *ngIf="import" [visible]="import !== undefined" [style]="{width:'650px'}" [showHeader]="false">
  <header>
    <h5>Import - {{selectedAdn.name}}</h5>
  </header>
  <section>
    <textarea pInputTextarea [(ngModel)]="import.text" [style]="{height:'50vh'}"></textarea>
  </section>
  <footer>
    <button pButton label="Apply" class="p-button-sm p-button-outlined" (click)="applyImport()"></button>
    <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="closeImport()"></button>
  </footer>
</p-dialog>
