import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { AppPermission } from '../../_shared/models/user';
import { ApiService } from '../../_shared/services/api.service';
import { AuthService } from '../../_shared/services/auth.service';
import { UiService } from '../../_shared/services/ui.service';
import { AdnImportComponent } from '../adn-import/adn-import.component';

@Component({
  selector: 'app-adn-mapper',
  templateUrl: './adn-mapper.component.html',
  styleUrls: ['./adn-mapper.component.scss']
})
export class AdnMapperComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authService: AuthService) { }

  adns: DroppableAdn[];
  unassigned: DroppableAdn;
  draggedStation: DraggableStation;
  selectedAdn: DroppableAdn;
  @ViewChild('menu', { static: false }) menu: Menu;
  import;
  menuItems: MenuItem[];
  canEdit: boolean;
  options: any = {};
  profileId: number;
  dirty = false;

  ngOnInit() {
    this.canEdit = this.authService.isPermitted(AppPermission.mngADNMap);

    this.apiService.get({
      method: "lookup/adn/mappingProfiles",
      onSuccess: (r) => {
        this.options.profiles = r.result;
        this.profileId = this.options.profiles[0].id;
        this.refresh();
      }
    });
  }

  refresh() {
    this.apiService.get({
      method: "adn/mappings",
      blockUi: true,
      params: { mappingProfileId: this.profileId },
      onSuccess: (r) => {
        this.adns = r.result.mapped;
        this.unassigned = { adnId: null, name: "Unassigned", stations: r.result.unmapped };
        this.adns.push(this.unassigned);

        this.adns.forEach(adn => {
          this.sort(adn);
        });

        this.setDirty(false);
      }
    });
  }

  openMenu($event, adn: DroppableAdn) {
    this.selectedAdn = adn;

    this.menuItems = [
      { label: 'Edit', icon: 'pi pi-pencil', command: () => { this.showImport(); } },
      { label: 'Clear', icon: 'pi pi-trash', command: () => { this.clearStations(adn); }, visible: adn !== this.unassigned }
    ];

    this.menu.show($event);
  }

  showImport() {
    var text = this.selectedAdn.stations.map(s => s.callSign).join("\n");
    this.import = { text };
  }

  closeImport() {
    this.import = undefined;
  }

  applyImport() {
    this.clearStations(this.selectedAdn);
    var allStations = this.adns.map(x => x.stations).flat();

    var callSigns: string[] = this.import.text.split("\n");
    callSigns.forEach(callSign => {
      var match = allStations.find(s => s.callSign.toLowerCase() === callSign.toLowerCase());
      if (match) {
        this.move(match, this.selectedAdn);
      }
    });

    this.import = undefined;
  }

  clearStations(adn: DroppableAdn) {
    if (adn === this.unassigned)
      return;

    this.unassigned.stations = this.unassigned.stations.concat(adn.stations);
    this.sort(this.unassigned);

    adn.stations = [];

    this.setDirty(true);
  }

  clearAll() {
    this.adns.forEach((adn) => { this.clearStations(adn) });
  }

  dragStart(station: DraggableStation) {
    this.draggedStation = station;
  }

  drop(adn: DroppableAdn) {
    if (this.draggedStation) {
      this.move(this.draggedStation, adn);
      this.dragEnd();
    }
  }

  dragEnd() {
    this.draggedStation = null;
  }

  move(station: DraggableStation, adn: DroppableAdn) {
    this.adns.forEach(a => {
      a.stations = a.stations.filter(s => s.stationId !== station.stationId);
    });

    adn.stations.push(station);
    this.sort(adn);

    this.setDirty(true);
  }

  sort(adn: DroppableAdn) {
    adn.stations.sort((a, b) => a.callSign.localeCompare(b.callSign));
  }

  save() {
    var items = [];
    this.adns.forEach(adn => {
      adn.stations.forEach(s => {
        items.push({ stationId: s.stationId, adnId: adn.adnId });
      });
      adn.stations = [];
    });

    this.apiService.post({
      method: "adn/mappings/save",
      data: { items, mappingProfileId: this.profileId },
      blockUi: true,
      onSuccess: (r) => {
        this.refresh();
        this.uiService.toast.success("Default ADN Mappings updated");
      }
    });
  }

  cancel() {
    this.refresh();
  }

  setDirty(val: boolean) {
    this.dirty = val;
  }

  profileClicked() {
    if (this.dirty)
      this.uiService.toast.info("There are unsaved changes. Please save or cancel first.");
  }

  openImport() {
    var adns = this.adns.map(x => ({ id: x.adnId, name: x.name }));

    var dialog = this.uiService.open.dialog(AdnImportComponent, { header: "ADN Import", width: '700px', data: { adns } });

    dialog.onClose.subscribe(r => {
      if (r?.result) {
        var allStations = this.adns.map(x => x.stations).flat();

        for (const adnId of Object.keys(r.result)) {
          var stationIds = r.result[adnId];
          stationIds.forEach(stationId => {
            var station = allStations.find(s => s.stationId === stationId);
            var adn = this.adns.find(a => a.adnId === parseInt(adnId)) || this.unassigned;
            if (station && adn) {
              this.move(station, adn);
            }
          });
        }
      }
    });
  }
}

interface DroppableAdn {
  adnId: number;
  name: string;
  stations: DraggableStation[];
  import?: string;
}

interface DraggableStation {
  stationId: number;
  callSign: string;
}
