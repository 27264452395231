<div class="page-header">
  <h3 class="title">Blackout Rules</h3>
  <p-dropdown [options]="options.stations" [(ngModel)]="ui.stationId" (onChange)="getRules()" appendTo="body"
              optionLabel="name" optionValue="id" placeholder="All Stations" [showClear]="true" [filter]="true"
              scrollHeight="350px" [style]="{width:'200px'}"></p-dropdown>
  <div class="button-group" *ngIf="ui.canEdit">
    <button pButton label="Add Rule" class="p-button-sm p-button-outlined" (click)="addRule()"></button>
  </div>
</div>
<div class="page-body">
  <p-table *ngIf="rules" [value]="rules" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="header">
      <tr>
        <th>Rule</th>
        <th>Station</th>
        <th>Partners</th>
        <th>Title</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Active</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{row.signalEventRuleId}}</td>
        <td>{{row.callSign}}</td>
        <td><div *ngFor="let x of row.partners">{{x.name}}</div></td>
        <td>{{row.titleToMatch}}</td>
        <td>{{(row.airingsAfterDate|date:'shortDate')||'-'}}</td>
        <td>{{(row.airingsBeforeDate|date:'shortDate')||'-'}}</td>
        <td>
          <i class="pi pi-check" *ngIf="row.active"></i>
          <i class="pi pi-times text-danger" *ngIf="!row.active"></i>
        </td>
        <td>
          <div class="row-actions">
            <button pButton class="p-button-sm p-button-outlined" label="Modify" (click)="editRule(row)" *ngIf="ui.canEdit"></button>
            <button pButton class="p-button-sm p-button-outlined" label="Run" (click)="runNow(row)" [disabled]="!row.active" *ngIf="ui.canEdit"></button>

            <a pButton href="" target="_blank" [routerLink]="['/blackout-schedule']" [queryParams]="{rule: row.signalEventRuleId}"
               class="p-button-sm p-button-outlined">Results</a>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-dialog *ngIf="selected" [visible]="selected !== undefined" [style]="{width:'1000px'}" [showHeader]="false">
  <section style="display: flex; flex-direction: column; row-gap: 15px">
    <h5>{{selected._isNew ? "New Rule" : "Rule #" + selected.signalEventRuleId}}</h5>
    <div class="row">
      <div class="col-md-6">
        <label>Station</label>
        <span *ngIf="selected._isNew">
          <p-multiSelect [options]="options.stations" [(ngModel)]="selected.stationIds" (onChange)="stationChanged()"
                         optionLabel="name" optionValue="id" [required]="true"
                         defaultLabel="Select Stations" [showClear]="true" appendTo="body"
                         [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} selected'" scrollHeight="350px"></p-multiSelect>
        </span>
        <span *ngIf="!selected._isNew">
          <p-dropdown [options]="options.stations" [(ngModel)]="selected.stationId" (onChange)="stationChanged()" appendTo="body"
                      optionLabel="name" optionValue="id" placeholder="Select Station" [showClear]="true" [filter]="true"
                      [required]="true" scrollHeight="350px"></p-dropdown>
        </span>
      </div>
      <div class="col-md-6">
        <label>Partners</label>
        <p-multiSelect [options]="options.partners" [(ngModel)]="selected.partnerIds" defaultLabel="Select Partners" [showClear]="true" appendTo="body"
                       optionLabel="name" optionValue="id" [disabled]="!options.partners?.length" [required]="true"
                       [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} selected'" scrollHeight="350px"></p-multiSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Title</label>
        <p-dropdown [options]="options.titles" [(ngModel)]="selected.titleToMatch" defaultLabel="Select Title"
                    optionLabel="title" optionValue="title" [showClear]="true" [filter]="true" [editable]="true"
                    appendTo="body" scrollHeight="300px" [virtualScroll]="true" [virtualScrollItemSize]="30"></p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label>Start Date</label>
        <p-calendar [(ngModel)]="selected.startDate" appendTo="body"
                    [minDate]="options.minDate" [maxDate]="options.maxDate" [showClear]="true" [required]="true"></p-calendar>
      </div>
      <div class="col-md-4">
        <label>End Date</label>
        <p-calendar [(ngModel)]="selected.endDate" appendTo="body"
                    [minDate]="options.minDate" [maxDate]="options.maxDate" [showClear]="true"></p-calendar>
      </div>
      <div class="col-md-4" style="margin-top: 30px">
        <p-checkbox [(ngModel)]="selected.active" [binary]="true" label="Active"></p-checkbox>
      </div>
    </div>
    <footer>
      <div>
        <button pButton label="Delete" class="p-button-sm p-button-outlined p-button-danger" (click)="deleteRule($event)" *ngIf="!selected._isNew"></button>
        <p-confirmPopup [style]="{width:'350px'}"></p-confirmPopup>
      </div>
      <div>
        <button pButton label="Save" class="p-button-sm p-button-outlined" (click)="saveRule()"></button>
        <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="closeDialog()"></button>
      </div>
    </footer>
  </section>
</p-dialog>
