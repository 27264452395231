import { Component, EventEmitter, ViewChild, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppPermission } from '../../../_shared/models/user';
import { ApiService } from '../../../_shared/services/api.service';
import { AuthService } from '../../../_shared/services/auth.service';
import { UiService } from '../../../_shared/services/ui.service';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  constructor(
    private uiService: UiService,
    private apiService: ApiService,
    private authService: AuthService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  @ViewChild('table') table: Table;
  user: User;
  options: any;
  canEdit: boolean;
  stationFilter: string;

  ngOnInit(): void {
    this.canEdit = this.authService.isPermitted(AppPermission.mngUser);

    this.refresh();
  }

  save() {
    if (!this.user.systemRoles?.length && !this.user.stationRoles?.length) {
      this.uiService.toast.error("User must be assigned to at least one role.");
      return;
    }

    // Update station roles attribute for post
    this.user.stationRoles = this.options.stationRoles.map(r => ({
      roleId: r.roleId,
      stationIds: this.options.stations.filter(s => (s["role" + r.roleId] === true)).map(s => s.id)
    }));

    this.apiService.post({
      method: "user/save",
      data: this.user,
      onSuccess: (r) => {
        this.uiService.toast.success(`User ${this.user.appUserId === 0 ? 'created' : 'updated'}`);
        this.close(true);
      },
      onError: (e) => {
        this.uiService.toast.error(e.message);
      }
    });
  }

  close(refresh = false) {
    this.ref.close({ refresh });
  }

  refresh() {
    if (this.config.data.appUserId === 0) {
      this.user = <User>{ appUserId: 0, active: true };
      this.getRoleInfo();
    }
    else {
      this.apiService.get({
        method: `user/${this.config.data.appUserId}`,
        onSuccess: (r) => {
          this.user = r.result;
          this.getRoleInfo();
        }
      });
    }
  }

  getRoleInfo() {
    this.apiService.get({
      method: `lookup/roleType`,

      onSuccess: (rt) => {
        var roleTypes = {
          systemRolesType: rt.result.find((rt: any) => (rt.name === "System")),
          stationRolesType: rt.result.find((rt: any) => (rt.name === "Station"))
        };

        this.apiService.getMultiple([
          `lookup/stations/admin`,
          `lookup/roleType/${roleTypes.systemRolesType.roleTypeId}/roles?includeInactive=false`,
          `lookup/roleType/${roleTypes.stationRolesType.roleTypeId}/roles?includeInactive=false`,
        ]).subscribe(mr => {

          this.options = {
            stations: mr[0].result,
            systemRoles: mr[1].result,
            stationRoles: mr[2].result
          }

          // Set station role checkboxes
          this.options.stationRoles.forEach(r => {
            var stationIdsWithRole = this.user.stationRoles.find(usr => usr.roleId === r.roleId)?.stationIds || [];
            this.options.stations.forEach(s => {
              s[`role${r.roleId}`] = stationIdsWithRole.includes(s.id);
            });
            this.selectionChanged(r);
          });
        });
      }
    })
  };

  filterStations() {
    if (!this.table) return;

    this.table.filter(this.stationFilter, 'name', 'contains');
  }

  selectAllNone(role) {
    this.options.stations.forEach(s => {
      s['role' + role.roleId] = role.all;
    });
  }

  selectionChanged(role) {
    role.all = this.options.stations.every(x => x['role' + role.roleId]);
  }
}

interface User {
  appUserId: number,
  oktaLogin: string,
  firstName: string,
  lastName: string,
  active: boolean,
  systemRoles: number[],
  stationRoles: StationRole[]
}

interface StationRole {
  roleId: number,
  stationIds: number[]
}
